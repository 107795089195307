import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onObstacleFormTyping } from "../../store/features/obstacles/obstacles.slice";
import x from "./form.module.scss";
import classNames from "classnames";
import { CombinedState } from "../../store/store";

export type MilestoneFormFieldNames =
  | "description"
  | "responsiblePerson"
  | "weeks"
  | "months"
  | "years"
  | "name";

type MilestoneFormProps = {
  obstacleKey: string;
  obstacleOrder: number;
};

const Form: FC<MilestoneFormProps> = ({ obstacleKey, obstacleOrder }) => {
  const dispatch = useDispatch();

  const values = useSelector<CombinedState, any>(
    (state) =>
      state.obstacles.obstacles.find((obstacle) => obstacle.key === obstacleKey)
        ?.milestone
  );

  const onFormTyping = (fieldName: MilestoneFormFieldNames) => (e: any) => {
    // TODO: Add debounce function before saving value to the store.
    dispatch(
      onObstacleFormTyping({
        obstacleKey,
        fieldName,
        fieldValue: e.target.value,
      })
    );
  };

  return (
    <form className={x.form}>
      <div className={x.header}>
        <h3 className={x.title}>
          Meilenstein
          <span className={x.step}>{obstacleOrder}</span>
        </h3>
      </div>
      <div className={x.body}>
        <input
          className={x.input}
          type="text"
          placeholder="Name"
          name=""
          maxLength={22}
          onChange={onFormTyping("name")}
          value={values?.name}
        />
        <textarea
          onChange={onFormTyping("description")}
          className={x.textarea}
          name=""
          placeholder="Erläuterung"
          maxLength={137}
          value={values?.description}
        />
        <textarea
          onChange={onFormTyping("responsiblePerson")}
          className={classNames(x.textarea, x.second)}
          name=""
          placeholder="Verantwortliche Personen"
          maxLength={102}
          value={values?.responsiblePerson}
        />
        <p className={x.selectTitle}>erreicht bis:</p>
        <div className={x.selectBox}>
          <div className={x.selectWrapper}>
            <div className={x.selectIcon} />
            <select
              onChange={onFormTyping("weeks")}
              className={x.select}
              value={values?.weeks}
            >
              <option value="">Wochen</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
          <div className={x.selectWrapper}>
            <div className={x.selectIcon} />
            <select
              onChange={onFormTyping("months")}
              className={x.select}
              value={values?.months}
            >
              <option value="">Monate</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>
          <div className={x.selectWrapper}>
            <div className={x.selectIcon} />
            <select
              onChange={onFormTyping("years")}
              className={x.select}
              value={values?.years}
            >
              <option value="">Jahre</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
