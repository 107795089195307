import React, { FC } from 'react';
import { DateTime, Settings } from 'luxon';
import { Document, Page, StyleSheet, Font, Text, View, Image, Link } from '@react-pdf/renderer';
import ContainerImage from './assets/container.png';
import Flag from './assets/flag.png';
import Mountain4 from './assets/mountain-4.png';
import Mountain3 from './assets/mountain-3.png';
import Mountain2 from './assets/mountain-2.png';
import Mountain1 from './assets/mountain-1.png';

import MilestoneBox from './assets/milestone-border.png';
import ObstacleCardTop from './assets/obstacle_card_top.png';
import ObstacleCardBottom from './assets/obstacle_card_bottom.png';
import ObstacleCardHorizontal from './assets/obstacle_card_divider.png';

import StickerShadow from '../../assets/images/stickers/shadow.png';
import ObstacleShadow from '../../assets/images/obstacles/obstacle-shadow.png';

import Authority from './assets/authority.png';
import { StickerItem } from '../../store/features/stickers/stickers.mocks';
import { CharacterItem } from '../../store/features/characters/characters.mocks';
import { ObstacleItem } from '../../store/features/obstacles/obstacles.mocks';

Font.register({
    family: 'Geogrotesque',
    fonts: [
        { src: '/fonts/Geogrotesque-Rg.otf', fontStyle: 'normal', fontWeight: 'normal' },
        { src: '/fonts/Geogrotesque-SmBd.otf', fontStyle: 'normal', fontWeight: 'bold' },
        { src: '/fonts/Geogrotesque-RgIt.otf', fontStyle: 'italic', fontWeight: 'normal' },
        { src: '/fonts/Geogrotesque-SmBdIt.otf', fontStyle: 'italic', fontWeight: 'bold' }
    ]
});

Font.register({
    family: 'Roboto',
    fonts: [
        { src: '/fonts/Roboto-Medium.otf', fontStyle: 'normal', fontWeight: 500 },
    ]
});

Font.register({
    family: 'Barlow',
    fonts: [
        { src: '/fonts/BarlowSemiCondensed-SemiBold.otf', fontStyle: 'normal', fontWeight: 600 },
    ]
});


interface IMap {
    destination: string;
    danger: StickerItem[];
    warning: StickerItem[];
    success: StickerItem[];
    characters: CharacterItem[];
    obstacles: ObstacleItem[];
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#014364'
    },
    title: {
        fontFamily: 'Geogrotesque',
        fontStyle: 'italic',
        fontWeight: 700,
        fontSize: '52px',
        color: '#FFFFFF',
        textAlign: 'center'
    },
    titleContainer: {
        textAlign: 'center',
        marginTop: '24px',
        marginBottom: '24px'
    },
    content: {
        minHeight: '1200px',
        minWidth: '200px',
        position: 'relative'
    },
    contentImage: {
        position: 'absolute',
        top: 0,
        left: '29px',
        width: '1133px',
        height: '1200px'
    },
    contentFlag: {
        position: 'absolute',
        top: '75px',
        left: '430px',
        width: '64px',
        height: '65px'
    },
    destination: {
        fontFamily: 'Geogrotesque',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20pt',
        color: '#78C0DD',
        textAlign: 'left',
        marginLeft: '515px',
        marginTop: '92px'
    },
    mountain4: {
        position: 'absolute',
        top: '97px',
        left: '13px',
        width: '1165px',
        height: '1073px'
    },
    mountain3: {
        position: 'absolute',
        top: '247px',
        left: '5px',
        width: '1181px',
        height: '918px'
    },
    mountain2: {
        position: 'absolute',
        top: '357px',
        left: '5px',
        width: '1181px',
        height: '818px'
    },
    mountain1: {
        position: 'absolute',
        top: '457px',
        left: '5px',
        width: '1181px',
        height: '726px'
    },
    legendContainer: {
        marginTop: '5px',
        marginLeft: '46px',
        width: '1133px',
        height: '338px',
        position: 'relative'
    },
    legend: {
        fontFamily: 'Geogrotesque',
        fontStyle: 'italic',
        fontWeight: 700,
        fontSize: '25pt',
        color: '#FFFFFF',
    },
    legendGroupContainer: {
        width: '1111px',
        display: 'flex',
        flexDirection: 'row',
    },
    legendDetailsContainer: {
        width: '937px',
        marginLeft: '20px',
        marginTop: '12px',
        display: 'flex',
        flexDirection: 'row'
    },
    legendAuthorsContainer: {
        marginTop: '20px',
        position: 'absolute',
        right: '126px'
    },
    legendAuthority: {
        position: 'absolute',
        top: '140px',
        width: '126px',
        height: '183px'
    },
    legendAuthorsLink: {
        fontFamily: 'Geogrotesque',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '6pt',
        color: '#231F20',
        position: 'absolute',
        top: '280px',
        marginLeft: '37px'
    },
    legendDetailsTitle: {
        fontFamily: 'Geogrotesque',
        fontStyle: 'italic',
        fontWeight: 700,
        fontSize: '20pt',
        color: '#FFFFFF',
    }
});


const Map: FC<IMap> = ({ destination, danger, warning, success, characters, obstacles }) => {

    const mapSticker = (s: StickerItem) => (<View style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '17px'
    }}>
        <View
            style={{
            width: '36px',
            height: '36px',
            minWidth: '36px',
            minHeight: '36px',
            textAlign: 'center'
        }}>
        <Image
            style={{
                width: '42.68px',
                height: '42.68px',
                left: '-3px',
                top: '-3px',
                objectFit: 'contain',
                position: 'absolute'
            }}
            src={StickerShadow}
            />
        <Image
            key={s.key}
            style={{
                width: '36px',
                height: '36px',
                objectFit: 'contain',
                position: 'absolute'
            }}
            src={s.png} />
        </View>
        <Text style={{
            fontSize: '6.48pt',
            fontFamily: 'Geogrotesque',
            fontStyle: 'normal',
            fontWeight: 700,
            marginTop: '12px',
            marginLeft: '9px',
            color: '#FFFFFF',
            minWidth: '63px',
            maxWidth: '63px'
        }}>{s.labelText}</Text>
    </View>);

    const dangers = danger.map(mapSticker);
    const warnings = warning.map(mapSticker);
    const succsesses = success.map(mapSticker);

    const charactersMap = characters.map(ch => (
        <View style={{ marginTop: '15px', display: 'flex', flexDirection: 'row' }}>
            <Image
                key={ch.key}
                style={{
                    width: '63px',
                    height: '63px'
                }}
                src={ch.image} />
            <View style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
                <Text style={{
                    fontSize: '8pt',
                    fontFamily: 'Geogrotesque',
                    fontStyle: 'italic',
                    fontWeight: 700,
                    color: '#FFFFFF'
                }}>{ch.name},{ch.age}</Text>
                <Text style={{
                    fontSize: '8pt',
                    fontFamily: 'Geogrotesque',
                    fontStyle: 'italic',
                    fontWeight: 400,
                    color: '#FFFFFF',
                    maxWidth: '224px'
                }}>{ch.text}</Text>
            </View>
        </View>
    ));

    const buildDateText = (o: ObstacleItem): string => {
        let result = '';

        if (o.milestone.weeks) {
            if (+o.milestone.weeks > 1) {
                result += `${o.milestone.weeks} Wochen`;
            } else {
                result += `${o.milestone.weeks} Woche`
            }
        }

        if (o.milestone.months) {
            if (result !== '')
                result += ', ';

            if (+o.milestone.months > 1) {
                result += `${o.milestone.months} Monate`;
            } else {
                result += `${o.milestone.months} Monat`;
            }
        }

        if (o.milestone.years) {
            if (result !== '')
                result += ', ';

            if (+o.milestone.years > 1) {
                result += `${o.milestone.years} Jahre`;
            } else {
                result += `${o.milestone.years} Jahr`;
            }
        }

        return `${result}.`;
    };

    const milestones = obstacles.map((o) => (
        <View style={{
            maxWidth: '108px',
            maxHeight: '114px',
            width: '108px',
            height: '114px',
            marginTop: '15px'
        }}>
            <Image
                style={{
                  width: '107px',
                  height: '117px',
                  position: 'absolute'
                }}
                src={MilestoneBox} />
            <Text style={{
                fontSize: '8pt',
                fontFamily: 'Geogrotesque',
                fontStyle: 'italic',
                fontWeight: 700,
                color: '#FFFFFF',
                maxWidth: '47px',
                position: 'absolute',
                left: '8px',
                top: '-5px'
            }}>Meilenstein {o.customOrder}</Text>
            <Text
            style={{
                fontSize: '7pt',
                fontFamily: 'Geogrotesque',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '#FFFFFF',
                marginLeft: '5px',
                marginTop: '5px'
            }}>{o.milestone.name},</Text>
            <Text style={{
                fontSize: '7pt',
                fontFamily: 'Geogrotesque',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '#FFFFFF',
                marginLeft: '5px',
            }}>{buildDateText(o)}</Text>
            <Text style={{
                fontSize: '7pt',
                fontFamily: 'Geogrotesque',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '#FFFFFF',
                marginLeft: '5px',
                marginTop: '10px',
                maxHeight: '30px',
                maxWidth: '98px',
                minHeight: '30px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>{o.milestone.description}</Text>
            <Text
                style={{
                    fontSize: '8pt',
                    fontFamily: 'Geogrotesque',
                    fontStyle: 'italic',
                    fontWeight: 700,
                    color: '#FFFFFF',
                    marginLeft: '5px',
                    marginTop: '10px',
                    maxHeight: '34px'
                }}
            >Verantwortliche Personen:</Text>
            <Text style={{
                fontSize: '7pt',
                fontFamily: 'Geogrotesque',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '#FFFFFF',
                marginLeft: '5px',
                maxHeight: '34px',
                maxWidth: '98px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>{o.milestone.responsiblePerson}</Text>
        </View>
    ));

    const renderObstacleCard = (odata: ObstacleItem) => {

        const weeks = odata.milestone.weeks || 0;
        const months = odata.milestone.months || 0;
        const years = odata.milestone.years || 0;

        Settings.defaultLocale = "de";
        let date = DateTime.now();
        date = date.plus({ weeks: +weeks || 0 });
        date = date.plus({ months: +months || 0 });
        date = date.plus({ years: +years || 0 });

        const week = date.weekNumber;
        const month = date.toLocaleString({ month: 'long' }, { locale: 'de' });
        const year = date.year;

        const orderByColors = (a: StickerItem, b: StickerItem): number => {

            if (a.color === b.color)
                return 0;

            if (a.color === 'danger' && (b.color === 'warning' || b.color === 'success'))
                return -1;

            if (a.color === 'success' && b.color === 'warning')
                return -1;

            if (a.color === 'warning' && (b.color === 'success' || b.color === 'danger'))
                return 1;

            return 0;
        };

        const stickers = [...odata.milestone.pickedStickers]
            .sort(orderByColors)
            .map(((s, idx) => (<View style={{
                width: '71px',
                height: '71px',
                marginTop: idx > 0 ? '8px' : 0
            }}>
                <Image
                    style={{
                        width: '84.16px',
                        height: '84.16px',
                        left: '-6.5px',
                        top: '-6.5px',
                        objectFit: 'contain',
                        position: 'absolute'
                    }}
                    src={StickerShadow}
                />
                <Image
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                    src={s.png} />
            </View>)));

        return (
            <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{
                width: '200px',
                height: '336px',
            }}>
                <Image
                    style={{
                        top: '-15.53px',
                        left: '-9.03px',
                        width: '219.67px',
                        height: '379.06px',
                        objectFit: 'contain',
                        position: 'absolute'
                    }}
                    src={ObstacleShadow}
                />
                {/* Header of card */}
                <View style={{
                    width: '200px',
                    height: '68px',
                }}>
                    {/* Full sized background */}
                    <Image style={{
                        position: 'absolute',
                        left: 0,
                        width: '200px',
                        height: '68px'
                    }} src={ObstacleCardTop} />
                    {/* Text, 1st line */}
                    <View style={{
                        width: 'auto',
                        height: '31px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginLeft: '14px',
                        marginTop: '4px',
                        marginRight: '22px',
                        fontSize: '20.93pt',
                        fontFamily: 'Geogrotesque',
                        fontStyle: 'italic',
                        fontWeight: 700,
                        color: '#024363',
                    }}>
                        <Text>Meilenstein</Text>
                        <Text>{odata.customOrder}</Text>
                    </View>
                    <View style={{
                        width: 'auto',
                        height: '24px',
                        flexDirection: 'column',
                        marginLeft: '14px',
                        marginRight: '14px',
                        fontSize: '10.46pt',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        color: '#000000',
                        opacity: 0.6
                    }}>
                        <Text>{odata.milestone.name}</Text>
                        <Text>KW {week}, {month}, {year}.</Text>
                    </View>
                    <Image style={{
                        position: 'absolute',
                        width: '200px',
                        height: '1px',
                        bottom: 0
                    }} src={ObstacleCardHorizontal} />
                </View>
                <View style={{
                    width: '200px',
                    height: '212px',
                    position: 'absolute',
                    backgroundColor: 'gray',
                    marginTop: '68px'
                }}>
                    <Image src={odata.image} />
                </View>
                <View style={{
                    position: 'absolute',
                    marginTop: '280px',
                    width: '200px',
                    height: '70px'
                }}>
                    <Image style={{ position: 'absolute', width: '200px', height: '70px' }} src={ObstacleCardBottom} />
                    <Text style={{
                        fontSize: '25pt',
                        fontFamily: 'Barlow',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        color: '#EEEEEE',
                        marginLeft: '14px',
                        marginRight: '14px',
                        marginTop: '2px',
                        textAlign: 'center'
                    }}>{odata.labelText}</Text>
                </View>
            </View>
            {/* Sticker of this obstacle card */}
                <View style={{ marginLeft: '15px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '101px', height: '234px' }}>
                    {stickers}
                </View>
            </View>
        );
    };

    let obstacle1 = null;
    let obstacle2 = null;
    let obstacle3 = null;
    let obstacle4 = null;

    if (obstacles.length === 2) {
        obstacle1 = renderObstacleCard( obstacles.filter(o => o.customOrder === 1)[0] );
        obstacle4 = renderObstacleCard( obstacles.filter(o => o.customOrder === 2)[0] );
    }

    if (obstacles.length === 3) {
            obstacle1 = renderObstacleCard( obstacles.filter(o => o.customOrder === 1)[0] );
            obstacle2 = renderObstacleCard( obstacles.filter(o => o.customOrder === 2)[0] );
            obstacle4 = renderObstacleCard( obstacles.filter(o => o.customOrder === 3)[0] );
    }

    if (obstacles.length === 4) {
        obstacle1 = renderObstacleCard( obstacles.filter(o => o.customOrder === 1)[0] );
        obstacle2 = renderObstacleCard( obstacles.filter(o => o.customOrder === 2)[0] );
        obstacle3 = renderObstacleCard( obstacles.filter(o => o.customOrder === 3)[0] );
        obstacle4 = renderObstacleCard( obstacles.filter(o => o.customOrder === 4)[0] );
    }

    return (
        <Document>
            <Page size="A2" orientation={"portrait"} style={styles.page}>
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>Corporate Culture Map</Text>
                </View>
                <View style={styles.content}>
                    <Image style={styles.contentImage} src={ContainerImage} />
                    <Image style={styles.mountain4} src={Mountain4} />
                    <Image style={styles.contentFlag} src={Flag} />
                    <Text style={styles.destination}>{destination}</Text>
                    <View style={{
                        position: 'absolute',
                        right: '511px',
                        bottom: '720px',
                        transform: 'scale(0.525)'
                    }}>{obstacle4}</View>
                    <Image style={styles.mountain3} src={Mountain3} />
                    <View style={{
                        position: 'absolute',
                        right: '259px',
                        bottom: '576px',
                        transform: 'scale(0.525)'
                    }}>{obstacle3}</View>
                    <Image style={styles.mountain2} src={Mountain2} />
                    <View style={{
                        position: 'absolute',
                        right: '615px',
                        bottom: '427px',
                        transform: 'scale(0.64)'
                    }}>{obstacle2}</View>
                    <Image style={styles.mountain1} src={Mountain1} />
                    <View style={{
                        position: 'absolute',
                        right: '224px',
                        bottom: '123px',
                        transform: 'scale(0.7)'
                    }}>{obstacle1}</View>
                </View>
                <View style={styles.legendContainer}>
                    <Text style={styles.legend}>Legende</Text>
                    <View style={styles.legendGroupContainer}>
                        <View style={styles.legendDetailsContainer}>
                            <View style={{ width: '339px', overflow: 'hidden' }}>
                                <Text style={styles.legendDetailsTitle}>Ausrüstungsgegenstände</Text>
                                <View style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '339px',
                                    maxWidth: '339px'
                                }}>
                                    <View>{dangers}</View>
                                    <View style={{ marginLeft: '14px'}}>{succsesses}</View>
                                    <View style={{ marginLeft: '14px'}}>{warnings}</View>
                                </View>
                            </View>
                            <View style={{ width: '306px', marginLeft: '30px', overflow: 'hidden' }}>
                                <Text style={styles.legendDetailsTitle}>Mitreisende</Text>
                                <View style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    {charactersMap}
                                </View>
                            </View>
                            <View style={{ width: '235px', marginLeft: '28px' }}>
                                <Text style={styles.legendDetailsTitle}>Meilensteine</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: '236px', justifyContent: 'space-between' }}>
                                    {milestones}
                                </View>
                            </View>
                        </View>
                        <View style={styles.legendAuthorsContainer}>
                            <Image style={styles.legendAuthority} src={Authority} />
                            <Text style={{...styles.legendAuthorsLink, marginTop: '5pt'}}>Mehr information auf:</Text>
                            <Link style={{...styles.legendAuthorsLink, marginTop: '13pt', marginLeft: '30px'}} src={"www.cerri.iao.fraunhofer.de"}>www.cerri.iao.fraunhofer.de</Link>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default Map;
