import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabNames, TabSubItem } from "../../store/features/rating/rating.mocks";
import { selectRatingForSubItem } from "../../store/features/rating/rating.slice";
import x from "./evaluation-card.module.scss";

interface IEvaluationCard {
  tabName: TabNames;
  card: TabSubItem;
}

const EvaluationCard: FC<IEvaluationCard> = ({ tabName, card }) => {
  const { key, name, description, rating, order } = card;

  const [value, setValue] = useState<number>(0);
  useEffect(() => {
    setValue(rating);
  }, [key, rating]);

  const dispatch = useDispatch();
  const onChangeRating = (rating: number) => () => {
    setValue(rating);
    dispatch(
      selectRatingForSubItem({
        tabName,
        subItemKey: key,
        subItemRating: rating,
      })
    );
  };

  return (
    <div className={x.card}>
      <h3 className={x.cardTitle}>{name}</h3>
      <p className={x.cardSubtitle}>{description}</p>
      <div className={x.cardButtons}>
        {[...Array(10)].map((_, idx) => (
          <button
            className={classNames(x.cardButton, {
              [x.selected]: value === idx + 1,
            })}
            key={`${order}_${key}_${idx + 1}`}
            value={value}
            onClick={onChangeRating(idx + 1)}>
            {idx + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EvaluationCard;
