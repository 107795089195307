import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Body from "./components/body/body";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import {
  backStep,
  nextStep,
  onChangeStepsQuantity,
} from "./store/features/navigation/navigation.state";
import { ObstacleItem } from "./store/features/obstacles/obstacles.mocks";
import { CombinedState, RootState } from "./store/store";
import Modal from "./components/modal/modal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// prevent errors to spam console (mostly react deprecation parts)
console.warn = console.error = () => { /* all console errors and warns are disabled */ };

function App() {
  const dispatch = useDispatch();
  const selectedObstacles: ObstacleItem[] = useSelector(
    (state: CombinedState) =>
      state.obstacles.obstacles.filter((s) => s.isSelected)
  );
  const total = useSelector((state: RootState) => state.navigation.total);
  const current = useSelector((state: RootState) => state.navigation.current);

  const handleNext = () => {
    if (current < total - 1) {
      dispatch(nextStep());
      /**
       * @description Defining dynamic obstacles quantity.
       */
      if (current === 6) {
        dispatch(
          onChangeStepsQuantity({ obstaclesQuantity: selectedObstacles.length })
        );
      }
    }
  };

  const handleBack = () => {
    if (current > 0) dispatch(backStep());
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Header handleBack={handleBack} />
      <Body step={current} />
      <Footer step={current} total={total} handleNext={handleNext} />
      <Modal />
    </DndProvider>
  );
}

export default App;
