import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import {
  StickerItem,
  StickerSection,
} from "../../../store/features/stickers/stickers.mocks";
import { AvailableStickerSectionColors } from "../../../store/features/stickers/stickers.slice";
import { CombinedState } from "../../../store/store";
import TitledSticker from "../../titled-sticker/titled-sticker";

import x from "./step-4.module.scss";

function renderSectionStickers(
  stickers: StickerItem[],
  color: AvailableStickerSectionColors
) {
  return stickers.map(({ isSelected, key, displayValue, labelText, src }) => {
    return isSelected ? (
      <TitledSticker
        key={key}
        color={color}
        displayValue={displayValue}
        labelText={labelText}
        stickerKey={key}
        src={src}
      />
    ) : null;
  });
}

interface IStepFour {}

const StepFour: FC<IStepFour> = () => {
  const stickerSections = useSelector(
    (state: CombinedState) => state.stickers.stickerSections
  );

  const danger: StickerSection = stickerSections["danger"];
  const warning: StickerSection = stickerSections["warning"];
  const success: StickerSection = stickerSections["success"];

  const dispatch = useDispatch();

  useEffect(() => {
    const allStickers = stickerSections["danger"].stickers
      .concat(stickerSections["warning"].stickers)
      .concat(stickerSections["success"].stickers)
      .filter((s) => s.isSelected)
      .map((s) => s.labelText);

    const isValid = !allStickers.includes("");
    dispatch(onChangeValid({ isValid, path: STATE_PATHS.LABELING_STICKERS }));
  }, [dispatch, stickerSections]);

  return (
    <div className={x.container}>
      <div>
        {danger.stickers.map((item) => item.isSelected).includes(true) && (
          <>
            <h3 className={x.subtitle}>Zuständigkeiten & Initiativen</h3>
            <div className={x.stack}>
              {renderSectionStickers(danger.stickers, "danger")}
            </div>
          </>
        )}
      </div>
      <div>
        {success.stickers.map((item) => item.isSelected).includes(true) && (
          <>
            <h3 className={x.subtitle}>Fähigkeiten & Erfahrungen</h3>
            <div className={x.stickersStack}>
              {renderSectionStickers(success.stickers, "success")}
            </div>
          </>
        )}
      </div>
      <div>
        {warning.stickers.map((item) => item.isSelected).includes(true) && (
          <>
            <h3 className={x.subtitle}>Methoden & Tools</h3>
            <div className={x.stickersStack}>
              {renderSectionStickers(warning.stickers, "warning")}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StepFour;
