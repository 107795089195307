import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import { ObstacleItem } from "../../../store/features/obstacles/obstacles.mocks";
import { onChangeObstacleState } from "../../../store/features/obstacles/obstacles.slice";
import { CombinedState } from "../../../store/store";
import ObstacleCard from "../../obstacle-card/obstacle-card";
import x from "./step-5.module.scss";

interface IStepFive {}

const StepFive: FC<IStepFive> = () => {
  const [chosenCards, setChosenCards] = useState<string[]>([]);

  const obstacles: ObstacleItem[] = useSelector(
    (state: CombinedState) => state.obstacles.obstacles
  );

  useEffect(() => {
    const initialSelectedStickers: string[] = [];
    obstacles.forEach(({ key, isSelected }) => {
      if (isSelected) {
        initialSelectedStickers.push(key);
      }
    });

    setChosenCards(initialSelectedStickers);
  }, [obstacles]);

  const dispatch = useDispatch();

  useEffect(() => {
    const allObstacles = obstacles.filter((s) => s.isSelected);
    const isValid = allObstacles.length > 1 && allObstacles.length < 5;
    dispatch(onChangeValid({ isValid, path: STATE_PATHS.PICKING_OBSTACLES }));
  }, [dispatch, obstacles]);

  const onObstacleSelect = (obstacleKey: string) => () => {

    // don't allow to select more than 4 cards
    const selected = obstacles.filter((s) => s.isSelected).map((s) => s.key);
    if (selected.length >= 4 && !selected.includes(obstacleKey))
      return;

    setChosenCards((prev) => {
      if (prev.includes(obstacleKey)) {
        return prev.filter((key) => key !== obstacleKey);
      }

      return [...prev, obstacleKey];
    });

    dispatch(onChangeObstacleState({ obstacleKey }));
  };

  return (
    <>
      <p className={x.subtitle}>
        Bei Eurer Wanderung wird es die ein oder andere Stolperstelle geben.
        Überlegt, welche Herausforderungen Euch voraussichtlich auf Eurem Weg
        zum Ziel begegnen werden und wählt Bilder aus, die symbolisch für die
        Herausforderungen und ihre Schwierigkeitsgrade stehen.
      </p>
      <div className={x.cards}>
        {obstacles.map(({ key, image, text }) => (
          <ObstacleCard
            key={key}
            image={image}
            onclick={onObstacleSelect(key)}
            classes={classNames(x.card, {
              [x.selectedCard]: chosenCards.includes(key),
            })}
            text={text}
          />
        ))}
      </div>
    </>
  );
};

export default StepFive;
