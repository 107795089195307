import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import { ObstacleItem } from "../../../store/features/obstacles/obstacles.mocks";
import { onPickMilestoneSticker, onRemoveMilestoneSticker } from "../../../store/features/obstacles/obstacles.slice";
import { StickerItem } from "../../../store/features/stickers/stickers.mocks";
import { CombinedState } from "../../../store/store";
import Form from "../../form/form";
import ObstacleCard from "../../obstacle-card/obstacle-card";
import x from "./step-9.module.scss";

type ObstacleDetails = {
  obstacle: ObstacleItem;
};

const StepNine: FC<ObstacleDetails> = ({ obstacle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { milestone, key } = obstacle;


    // description: string;
    // responsiblePerson: string;
    // weeks: string;
    // months: string;
    // years: string;
    // name: string;
    // pickedStickers: StickerItem[];
    const isValid = !!milestone.description && !!milestone.responsiblePerson && !!milestone.name
      && !!milestone.pickedStickers.length && (!!milestone.weeks || !!milestone.months || !!milestone.years);

    dispatch(
      onChangeValid({
        isValid,
        path: `${STATE_PATHS.OBSTACLE_MILESTONE}_${key}`,
      })
    );
  }, [dispatch, obstacle]);

  const { danger, warning, success } = useSelector(
    (state: CombinedState) => state.stickers.stickerSections
  );

  const stickerDisplayable = (s: StickerItem) => {
    const selected = Boolean(obstacle.milestone.pickedStickers.find(({ key }) => s.key === key));

    return s.isSelected && !selected;
  };

  const filteredDangerStickers = danger.stickers.filter(stickerDisplayable);
  const filteredWarningStickers = warning.stickers.filter(stickerDisplayable);
  const filteredSuccessStickers = success.stickers.filter(stickerDisplayable);

  const onClickSticker = (sticker: StickerItem) => () => {
    dispatch(onPickMilestoneSticker({ obstacleKey: obstacle.key, sticker }));
  };

  const onClickSelected = (sticker: StickerItem) => () => {
    dispatch(onRemoveMilestoneSticker({ obstacleKey: obstacle.key, sticker }));
  }

  const addIconStyle = {
    width: '9px',
    height: '9px',
    '@media (min-width: 1440px)': {
      width: '18px',
      height: '18px',
    },
    '@media (min-width: 2540px)': {
      width: '27px',
      height: '27px',
    },
  };

  return (
    <>
      <p className={x.subtitle}>
        Um zu Eurem Ziel zu gelangen, benötigt Ihr eine Wanderroute. Überlegt,
        welche Ausrüstungsgegenstände Euch bei der Überwindung der dargestellten
        Herausforderung nützlich sind und setzt einen Meilenstein, mit dem Ihr
        die Herausforderung hinter Euch lassen könnt. Die Definition von
        Stichtag und Verantwortlichkeit hilft Euch bei der Umsetzung.
      </p>
      <div className={x.grid}>
        <div className={x.gridItem}>
          <ObstacleCard
            image={obstacle.image}
            text={obstacle.labelText}
            bodyClasses={x.obstacleCardBody}
            classes={x.obstacleCard}
          />
        </div>
        <div className={x.gridItem}>
          <div className={x.stickerStack}>
            {obstacle?.milestone?.pickedStickers?.length < 3 ? (
              <>
                <div className={classNames(x.sticker, x["white"])}>
                  <AddCircleOutlineIcon className={x.circleAddIcon} color="disabled" sx={addIconStyle} />
                </div>
                {[...Array(2 - obstacle.milestone.pickedStickers.length)].map(
                  (_, i) => (
                    <div
                      key={i + " picked_white"}
                      className={classNames(x.sticker, x["white"])}>
                      <AddCircleOutlineIcon color="disabled" sx={addIconStyle} />
                    </div>
                  )
                )}
              </>
            ) : null}
            {obstacle.milestone.pickedStickers.map((s) => (
              <div
                key={s.key + " picked"}
                onClick={onClickSelected(s)}
                className={classNames(x.sticker, x[s.color])}>
                <Tooltip title={s.labelText}>
                  <img src={s.src} alt={s.key} />
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
        <div className={x.gridItem}>
          <Form obstacleKey={obstacle.key} obstacleOrder={obstacle.customOrder} />
        </div>
        <div className={x.bottomGrid}>
          <div className={classNames(x.bottomGridItem)}>
            <div className={x.stickers}>
              {filteredDangerStickers.map((s) => (
                <div
                  key={s.key + " danger"}
                  onClick={onClickSticker(s)}
                  className={classNames(x.sticker, x["danger"])}>
                  <Tooltip title={s.labelText}>
                    <img src={s.src} alt={s.key} />
                  </Tooltip>
                </div>
              ))}
              {filteredDangerStickers.length < 4
                ? [...Array(4 - filteredDangerStickers.length)].map((_, i) => (
                    <div
                      key={i + " danger_white"}
                      className={classNames(x.sticker, x["white"])}
                    />
                  ))
                : null}
            </div>
            <p className={x.stickersTitle}>Zuständigkeiten & Initiativen</p>
          </div>
          <div className={classNames(x.bottomGridItem)}>
            <div className={x.stickers}>
              {filteredSuccessStickers.map((s) => (
                <div
                  key={s.key + " success"}
                  onClick={onClickSticker(s)}
                  className={classNames(x.sticker, x["success"])}>
                  <Tooltip title={s.labelText}>
                    <img src={s.src} alt={s.key} />
                  </Tooltip>
                </div>
              ))}
              {filteredSuccessStickers.length < 4
                ? [...Array(4 - filteredSuccessStickers.length)].map((_, i) => (
                    <div
                      key={i + " success_white"}
                      className={classNames(x.sticker, x["white"])}
                    />
                  ))
                : null}
            </div>
            <p className={x.stickersTitle}>Fähigkeiten & Erfahrungen </p>
          </div>
          <div className={classNames(x.bottomGridItem)}>
            <div className={x.stickers}>
              {filteredWarningStickers.map((s) => (
                <div
                  key={s.key + " warning"}
                  onClick={onClickSticker(s)}
                  className={classNames(x.sticker, x["warning"])}>
                  <Tooltip title={s.labelText}>
                    <img src={s.src} alt={s.key} />
                  </Tooltip>
                </div>
              ))}
              {filteredWarningStickers.length < 4
                ? [...Array(4 - filteredWarningStickers.length)].map((_, i) => (
                    <div
                      key={i + " warning_white"}
                      className={classNames(x.sticker, x["white"])}
                    />
                  ))
                : null}
            </div>
            <p className={x.stickersTitle}>Methoden & Tools</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepNine;
