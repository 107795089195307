import { createSlice } from "@reduxjs/toolkit";
import { initialRatingState as initialState } from "./rating.mocks";

export const ratingSlice = createSlice({
	name: "ratingStep",
	initialState,
	reducers: {
		selectTab: (state, { payload: { tabName } }) => {
			state.tabs.forEach((tab) => {
				tab.isSelected = tab.name === tabName;
			});
		},
		selectRatingForSubItem: (state, { payload: { tabName, subItemKey, subItemRating } }) => {
			state.tabs.forEach((tab) => {
				if (tab.name === tabName) {
					tab.subItems.forEach((subItem) => {
						if (subItem.key === subItemKey) {
							subItem.rating = subItemRating;
						}
					});
				}
			});
		},
		resetRatingState: () => initialState,
	},
});

export const { selectTab, selectRatingForSubItem, resetRatingState } = ratingSlice.actions;

export default ratingSlice.reducer;
