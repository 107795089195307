import ali from "../../../assets/images/people/ALI.png";
import andrej from "../../../assets/images/people/ANDREJ.png";
import brigitte from "../../../assets/images/people/BRIGITTE.png";
import burak from "../../../assets/images/people/BURAK.png";
import chantal from "../../../assets/images/people/CHANTAL.png";
import charlie from "../../../assets/images/people/CHARLIE.png";
import jean from "../../../assets/images/people/JEAN.png";
import julia from "../../../assets/images/people/JULIA.png";
import magdalena from "../../../assets/images/people/MAGDALENA.png";
import mandy from "../../../assets/images/people/MANDY.png";
import michael from "../../../assets/images/people/MICHAEL.png";
import selma from "../../../assets/images/people/SELMA.png";
import sineb from "../../../assets/images/people/SINEB.png";
import thomas from "../../../assets/images/people/THOMAS.png";

// export const personsToChoose = [
//   ali,
//   andrej,
//   brigitte,
//   burak,
//   chantal,
//   charlie,
//   jean,
//   julia,
//   magdalena,
//   mandy,
//   michael,
//   selma,
//   sineb,
//   thomas,
// ];

type IDummyData = {
  id: number;
  name: string;
  age: number;
  image: string;
  text: string;
};

export const dummyData: IDummyData[] = [
  {
    id: 1,
    name: "Mandy",
    image: mandy,
    age: 18,
    text: "Mandy programmiert bereits seit ihrem 13. Lebensjahr und begeistert sich neben ihrer Ausbildung auch sonst für alles Technische. Vor Kurzem hat sie zufällig von der neuen Digital-Initiative ihres Unternehmens erfahren. Eigentlich würde sie sich gerne mit einbringen, doch so richtig angesprochen fühlte sie sich bisher nicht.",
  },
  {
    id: 2,
    name: "Burak",
    age: 21,
    text: "Im Rahmen seines Praktikums hat Burak zahlreiche Einblicke in das Unternehmen und die unterschiedlichen Abteilungen bekommen. Dabei sind ihm auch Unstimmigkeiten, wie Spannungen im Team und fragwürdige Prozesse, aufgefallen. Gerne würde er das mit seiner Teamleiterin teilen, doch er hat Sorge, dass seine Kritik negativ auf ihn zurückfallen könnte.",
    image: burak,
  },
  {
    id: 3,
    name: "Charlie",
    age: 24,
    text: "Charlie fühlt sich häufig mit der Unternehmenskommunikation unwohl, denn sowohl in Ausschreibungen als auch internen Mails werden regelmäßig vor allem die Männer explizit adressiert. Eigentlich würde Charlie das gerne zur Sprache bringen, doch die Sorge, sich bei der Argumentation als transgender outen zu müssen, ist groß.",
    image: charlie,
  },
  {
    id: 4,
    name: "Sineb",
    age: 29,
    text: "Vor 3 Jahren hatte sie ihren Berufseinstieg. Als studierte Wirtschaftsinformatikerin mit einem Fokus auf Innovationen bringt sie Motivation und zahlreiche Ideen in das Unternehmen. Doch manchmal resigniert sie vor einer „das haben wir schon immer so gemacht“- Mentalität.",
    image: sineb,
  },
  {
    id: 5,
    name: "Thomas",
    age: 32,
    text: "Thomas ist alleinerziehender Vater in Teilzeit. Sein Kind holt er meistens gegen 16:00 Uhr aus der Kita ab. Manchmal hat er das frustrierende Gefühl, dass wichtige Informationen ihn später als die anderen erreichen.",
    image: thomas,
  },
  {
    id: 6,
    name: "Magdalena",
    age: 35,
    text: "Nach einem Jahr Elternzeit hat Magdalena ihre frühere Position wieder in Teilzeit aufgenommen. Dabei nimmt sie wahr, dass sie trotz weniger Stunden genauso viel wie vor ihrer Abwesenheit schafft. Gleichzeitig werden ihr weniger komplexe und verantwortungsvolle Aufgaben zugeteilt. Das schlägt sich langsam in ihrer Motivation nieder.",
    image: magdalena,
  },
  {
    id: 7,
    name: "Selma",
    age: 38,
    text: "Selma ist Teamleiterin in ihrem Unternehmen. Damit sie den Alltag mit ihrer Frau bestmöglich organisiert bekommt, würde sie gerne die Arbeitszeit so legen, dass ihr der Nachmittag frei zur Verfügung steht. Doch wann immer sie das versucht, erreichen sie dringliche Anrufe und Mails außerhalb ihrer Arbeitszeiten.",
    image: selma,
  },
  {
    id: 8,
    name: "Chantal",
    age: 40,
    text: "Als Führungskraft ist Chantal häufig bis in die frühen Abendstunden terminlich eingebunden. Vor kurzem äußerte ihr Partner im Anschluss an die Elternzeit wieder in Vollzeit arbeiten zu wollen. Chantal möchte ihn unterstützen, sieht aber hinsichtlich ihrer Arbeitszeiten keinen Handlungsspielraum.",
    image: chantal,
  },
  {
    id: 9,
    name: "Ali",
    age: 43,
    text: "Ali und seine Frau arbeiten beide in Vollzeit. Wenn eines ihrer Kinder krank ist, fühlt er sich unwohl die Kinderkrankentage in Anspruch zu nehmen. Er hat Sorge bei den Kolleg*innen als Pantoffelheld zu gelten. Gleichzeitig hat er keine Wahl, da sie als Familie vor allem auf das Einkommen seiner Frau angewiesen sind.",
    image: ali,
  },
  {
    id: 10,
    name: "Jean",
    age: 46,
    text: "Jean ist vergangenes Jahr zu seiner Frau nach Deutschland gezogen. Er spricht sehr gutes Deutsch. In größeren Besprechungen fällt es ihm dennoch manchmal schwer, mit seinem Anliegen durchzudringen. Das frustriert ihn, da er das Gefühl hat, dass viele seiner Ideen untergehen oder gar nicht erst zur Sprache kommen.",
    image: jean,
  },
  {
    id: 11,
    name: "Julia",
    age: 51,
    text: "Julia wird vor allem für ihre kreativen Ideen geschätzt. Ihre Inspiration schöpft sie vor allem aus ihren Freizeitprojekten. Ob Selbstversorgergarten oder Programmierkurs, Julias Aktivitäten laden sie immer wieder von Neuem auf. Doch sie sorgt sich, dass ihre Lebensgestaltung zukünftig nicht mit den Karrierepfaden ihres Unternehmens zusammenpasst.",
    image: julia,
  },
  {
    id: 12,
    name: "Michael",
    age: 53,
    text: "Im vergangenen Jahr haben Michael und sein Mann den Wohnsitz der Familie verlagert. Michael arbeitet seither mindestens zwei Tage pro Woche aus dem Homeoffice. Von dort schaltet er sich Besprechungen, häufig als Einziger, digital zu. Während der digitalen Videokonferenzen fällt ihm auf, wie schwer es ist, mit seinen Anliegen zur Sprache zu kommen.",
    image: michael,
  },
  {
    id: 13,
    name: "Andrej",
    age: 55,
    text: "Andrej kümmert sich neben seiner Vollzeitstelle um seinen dementen Vater. Für die meisten Situationen findet er eine Lösung, doch wenn sich Besprechungen in die Länge ziehen oder spontane Termine angesetzt werden, hat er häufig das Gefühl, beiden Welten nicht gerecht werden zu können.",
    image: andrej,
  },
  {
    id: 14,
    name: "Brigitte",
    age: 57,
    text: "20 Jahre Betriebszugehörigkeit machen sie zu einer Expertin des Unternehmens. Doch mit der Digitalisierung und den Veränderungen der letzten Jahre kamen auch Herausforderungen. Manchmal sorgt sie sich um ihren Platz im Unternehmen.",
    image: brigitte,
  },
];
