import React, { FC } from "react";

import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

import x from "./before-start.module.scss";

const BeforeStart: FC = () => {
  return (
    <React.Fragment>
      <div className={x.subtitle}>
        Da Ihr mit der Corporate Culture Map einen intensiven Strategieprozess
        zur Entwicklung Eurer Unternehmenskultur 4.0 durchlauft, empfehlen wir
        Euch dafür mindestens 1,5 Stunden Zeit im Team einzuplanen. <br />
        Besondere Vorkenntnisse sind für die Nutzung der Map nicht erforderlich.
      </div>
      <h3 className={x.requirments}>Was braucht Ihr für den Weg?</h3>
      <ul className={x.requirmentsItems}>
        <li className={x.requirmentsItem}>Mindestens 1,5 Stunden Zeit</li>
        <li className={x.requirmentsItem}>Ein Team aus 2 bis 6 Personen</li>
        <li className={x.requirmentsItem}>
          Einen gemeinsamen (Videokonferenz-)Raum
        </li>
        <li className={x.requirmentsItem}>
          Eine Moderationsperson, die ihren Bildschirm teilt und die Eingaben
          vornimmt
        </li>
      </ul>
      <h3 className={x.requirments}>Hinweis</h3>
      <p className={x.requirmentsText}>
        <SettingsBackupRestoreIcon /> Mit diesem Symbol könnt Ihr den kompletten
        Arbeitsprozess jederzeit zurücksetzen.
      </p>
    </React.Fragment>
  );
};

export default BeforeStart;
