import React, { FC, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useResetStorage from "../../shared/hooks/resetStorage";
import { toggleModal } from "../../store/features/modal/modal.slice";
import { RootState } from "../../store/store";

import x from "./modal.module.scss";

const Modal: FC = () => {
  const modal = useSelector((state: RootState) => state.modal);

  const { resetStorage } = useResetStorage();

  const dispatch = useDispatch();

  if (modal.modal)
    return (
      <div className={x.modalOverlay}>
        <div className={x.modalWindow}>
          <p className={x.modalText}>
            Seid ihr sicher, dass ihr den kompletten Arbeitsprozess zurücksetzen
            möchten?
          </p>
          <div className={x.modalButtons}>
            <button
              type="button"
              className={x.modalButton}
              onClick={() => {
                resetStorage();
                dispatch(toggleModal(false));
              }}>
              Ja
            </button>
            <button
              type="button"
              className={x.modalButton}
              onClick={() => {
                dispatch(toggleModal(false));
              }}>
              Nein
            </button>
          </div>
        </div>
      </div>
    );

  return null;
};

export default memo(Modal);
