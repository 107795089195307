import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CharacterItem } from "../../../store/features/characters/characters.mocks";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import { CombinedState } from "../../../store/store";
import x from "./step-8.module.scss";

interface IStepEight {}

const StepEight: FC<IStepEight> = () => {
  const characters: CharacterItem[] = useSelector(
    (state: CombinedState) => state.characters.characters
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      onChangeValid({ isValid: true, path: STATE_PATHS.DISPLAYING_CHARACTERS })
    );
  }, [dispatch, characters]);

  return (
    <>
      <p className={x.subtitle}>
        Perspektivenvielfalt ist eine Bereicherung für Eure Wanderung, da Ihr so
        Probleme und Lösungen seht, die Ihr sonst nicht erkannt hättet. Schlüpft
        daher gedanklich in die Lebenssituationen Eurer Mitreisenden und denkt
        sie beim Setzen der Meilensteine im nächsten Schritt mit.
      </p>
      <div className={x.persons}>
        {characters
          .filter((c) => c.isSelected)
          .slice(0, 3)
          .map(({ key, name, age, text, image, isSelected }) => {
            return isSelected ? (
              <div className={x.card} key={key}>
                <div className={x.image}>
                  <img src={image} alt={name} />
                </div>
                <div className={x.info}>
                  <div>
                    <h4 className={x.title}>
                      {name}, {age}
                    </h4>
                  </div>
                  <p className={x.description}>{text}</p>
                </div>
              </div>
            ) : null;
          })}
      </div>
    </>
  );
};

export default StepEight;
