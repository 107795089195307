import { createSlice } from "@reduxjs/toolkit";
import { configuration } from "../../../configuration";
import { RootState } from "../../store";

export enum STATE_PATHS {
	RATING = "RATING",
	GOAL = "GOAL",
	PICKING_STICKERS = "PICKING_STICKERS",
	LABELING_STICKERS = "LABELING_STICKERS",
	PICKING_OBSTACLES = "PICKING_OBSTACLES",
	LABELING_OBSTACLES = "LABELING_OBSTACLES",
	PICKING_CHARACTERS = "PICKING_CHARACTERS",
	DISPLAYING_CHARACTERS = "DISPLAYING_CHARACTERS",
	OBSTACLE_MILESTONE = "OBSTACLE_MILESTONE",
	FINAL_STEP = "FINAL_STEP",
}

export type INavigationState = {
	current: number;
	path: string;
	total: number;
	valid: boolean;
	isReadOnlyCharacters: boolean;
};

const initialNavigationState: INavigationState = {
	current: 0,
	path: "",
	total: configuration.steps.length - 1,
	valid: false,
	isReadOnlyCharacters: false,
};

export const navigationSlice = createSlice({
	name: "navigation",
	initialState: initialNavigationState,
	reducers: {
		nextStep: (state) => {
			state.current += 1;
		},
		backStep: (state) => {
			state.current -= 1;
		},
		onChangeValid: (state, { payload }) => {
			const { isValid, path } = payload;

			state.valid = isValid;
			state.path = path;

			/**
			 * @description Disabling possibility to re-pick characters after their first submitting.
			 */
			if (path === STATE_PATHS.DISPLAYING_CHARACTERS) {
				state.isReadOnlyCharacters = true;
			}
		},
		onChangeStepsQuantity: (state, { payload }) => {
			state.total = (configuration.steps.length - 1) + payload.obstaclesQuantity;
		},
		resetNavigationState: () => initialNavigationState,
	},
});

export const {
	nextStep,
	backStep,
	onChangeValid,
	resetNavigationState,
	onChangeStepsQuantity,
} = navigationSlice.actions;
export const selectCurrentSlide = (state: RootState) => state.navigation.current;
export const navigationReducer = navigationSlice.reducer;
