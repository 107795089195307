import {
  IsSelected,
  ItemKey,
  ItemOrder,
} from "../../../shared/interfaces/index";

export type TabSubItem = ItemKey & {
  name: string;
  description: string;
  order: number;
  rating: number;
};

export type Tab = IsSelected &
  ItemOrder & {
    name: TabNames;
    subItems: TabSubItem[];
  };

export enum TabNames {
  "Kollaboration" = "Kollaboration",
  "Wissen und Entwickung" = "Wissen und Entwickung",
  "Nachhaltigkeit und Soziales" = "Nachhaltigkeit und Soziales",
  "Chancengleichheit" = "Chancengleichheit",
}

export type RatingState = {
  tabs: Tab[];
};

// const mockedSubItems: TabSubItem[] = [...Array(5)].map((_, i) => ({
//   key: `${i + 1}`,
//   rating: 0,
//   order: i + 1,
//   name: "Transparenz und Vernetzung",
//   description:
//     "Digitale Kommunikation- und Kollaborationsplattformen (Teams, Asan, etc.) / Wissenplattformen/Abteilungsübergreifender Austausch/Flasche Hierarchien / Job-Roatation.",
// }));

const kollaborationSubItems: TabSubItem[] = [
  {
    key: `1`,
    rating: 0,
    order: 1,
    name: "Wertschätzung",
    description:
      "Respektvoller, freundlicher Umgang / Kommunikation auf Augenhöhe / Kommunikationsregeln / Pünktlichkeit / Soft Skill Trainings",
  },
  {
    key: `2`,
    rating: 0,
    order: 2,
    name: "Offenheit",
    description:
      "Gewaltfreie Kommunikation / Transparente Entscheidungen / Offene Meetingkultur / Offene Fehlerkultur / 360 Grad Feedback",
  },
  {
    key: `3`,
    rating: 0,
    order: 3,
    name: "Selbstverantwortung",
    description:
      "Selbstgeführte, autonome Teams / Kompetenzbasierte Verantwortlichkeiten / Handlungsspielräume / Partizipative Führung / Abwesenheit von Sanktionen",
  },
  {
    key: `4`,
    rating: 0,
    order: 4,
    name: "Vertrauen",
    description:
      "Ungehinderter Informationszugang / Gegenseitiges Vertrauen / Angemessene Privatsphäre / Selbstkontrolle und Evaluation / Vertrauensarbeitszeit",
  },
];

const wissenundEntwickungSubItems: TabSubItem[] = [
  {
    key: `1`,
    rating: 0,
    order: 1,
    name: "Transparenz",
    description:
      "Transparente Rollen- und Aufgabenbeschreibung / Regelmäßige Kommunikationsformate / Wissensplattformen und Informationsmedien / Wissenslunch / Fuck up Nights",
  },
  {
    key: `2`,
    rating: 0,
    order: 2,
    name: "Vernetzung",
    description:
      "Digitale Kommunikations- und Kollaborationsplattformen / Abteilungsübergreifender Austausch / Flache Hierarchien / Job-Rotation / Betriebsausflüge",
  },
  {
    key: `3`,
    rating: 0,
    order: 3,
    name: "Kreativität",
    description:
      "Nutzung von Kreativtechniken / Design Thinking / Zugriff auf Kreativmaterialien / Kreativräume / Meetings außerhalb des Büros",
  },
  {
    key: `4`,
    rating: 0,
    order: 4,
    name: "Innovation",
    description:
      "Interdisziplinäre Teams / Internationale Kollaborationen / Agile Projektarbeit, Scrum / Gemeinsame Visionen / Open Innovation",
  },
  {
    key: `5`,
    rating: 0,
    order: 5,
    name: "Lebenslanges Lernen",
    description:
      "Weiterbildungsangebote / Individuelle Coachings / Working Out Loud Zirkel / Möglichkeiten zur (Selbst-)Evaluation / Unternehmensinterne Meet ups",
  },
];
const nachhaltigkeitundSozialesSubItems: TabSubItem[] = [
  {
    key: `1`,
    rating: 0,
    order: 1,
    name: "Nachhaltigkeit",
    description:
      "Vegane und vegetarische Kantinenangebote / Dachbegrünung / Förderung von Fahrgemeinschaften / Elektrischer Fuhrpark, Fahrradflotte CO2-Kompensation",
  },
  {
    key: `2`,
    rating: 0,
    order: 2,
    name: "Gesundheit",
    description:
      "Abwechslungsreiche Kantinenangebote / Ergonomische Arbeitsplätze / Betriebliche Gesundheits- und Sportangebote / Achtsamkeitsangebote / Gesunde Work-Life-Balance",
  },
  {
    key: `3`,
    rating: 0,
    order: 3,
    name: "Individualisierung",
    description:
      "Unterstützung der beruflichen und privaten Selbstverwirklichung / Handlungsspielräume / Maßgeschneiderte Weiterentwicklungsmöglichkeiten / Angebot verschiedener Karriereanreize / Karriere in Teilzeit",
  },
  {
    key: `4`,
    rating: 0,
    order: 4,
    name: "Bedürfnisorientierung",
    description:
      "Orts- und zeitflexibles Arbeiten / Teilzeitarbeit / Unbefristete Arbeitsverträge / Ruheräume / Gemeinschaftsräume",
  },
];
const chancengleichheitSubItems: TabSubItem[] = [
  {
    key: `1`,
    rating: 0,
    order: 1,
    name: "Gleichberechtigung",
    description:
      "Gleichstellungsbeauftragte Person / Lohngerechtigkeit / Förderprogramme für Frauen / Freiwillige Frauenquote / Lebensphasenorientierte Karrieremodelle",
  },
  {
    key: `2`,
    rating: 0,
    order: 2,
    name: "Chancengleichheit",
    description:
      "Ergebnisorientierte Bewertungsprozesse / Flexible Arbeitsmodelle / Familienfreundliches Betriebsklima / Betriebliche Kinderbetreuungsangebote / Mentoring",
  },
  {
    key: `3`,
    rating: 0,
    order: 3,
    name: "Diversität",
    description:
      "Diversity-Sensibilisierung / Interkulturelle Angebote / Anonymisierte Bewerbungsverfahren / Diversity-Mitarbeitenden Netzwerke / Gebetsräume",
  },
  {
    key: `4`,
    rating: 0,
    order: 4,
    name: "Inklusion",
    description:
      "Barrierefreie Büroflächen / Assistenzsysteme am Arbeitsplatz / Inklusiver Sprachgebrauch / Inklusives Personalmanagement / Verhaltenskodex",
  },
];

export const initialRatingState: RatingState = {
  tabs: [
    {
      order: 1,
      name: TabNames["Kollaboration"],
      isSelected: false,
      subItems: [...kollaborationSubItems],
    },
    {
      order: 2,
      name: TabNames["Wissen und Entwickung"],
      isSelected: false,
      subItems: [...wissenundEntwickungSubItems],
    },
    {
      order: 3,
      name: TabNames["Nachhaltigkeit und Soziales"],
      isSelected: false,
      subItems: [...nachhaltigkeitundSozialesSubItems],
    },
    {
      order: 4,
      name: TabNames["Chancengleichheit"],
      isSelected: false,
      subItems: [...chancengleichheitSubItems],
    },
  ],
};
