import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { StickerSection } from "../../store/features/stickers/stickers.mocks";
import { onChangeStickerState } from "../../store/features/stickers/stickers.slice";
import { ReactComponent as CheckCircleIcon } from "../../assets/images/checkCircle.svg";

import x from "./stickers.module.scss";

interface IStickersProps {
  color: string;
  sectionData: StickerSection;
}

const Stickers: FC<IStickersProps> = ({ color, sectionData: { stickers } }) => {
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const initialSelectedStickers: string[] = [];
    stickers.forEach(({ key, isSelected }) => {
      if (isSelected) {
        initialSelectedStickers.push(key);
      }
    });
    setSelected(initialSelectedStickers);
  }, [stickers]);

  const dispatch = useDispatch();

  const onStickerSelect = (stickerKey: string) => () => {
    setSelected((prev) => {
      if (prev.includes(stickerKey)) {
        return prev.filter((key) => key !== stickerKey);
      }

      return [...prev, stickerKey];
    });

    dispatch(onChangeStickerState({ sectionKey: color, stickerKey }));
  };

  return (
    <div className={x.stickersBlock}>
      <div className={x.stickers}>
        <h4 className={x.title}>
          {color === "danger" && "Zuständigkeiten & Initiativen"}
          {color === "success" && "Fähigkeiten & Erfahrungen"}
          {color === "warning" && "Methoden & Tools"}
        </h4>
        <ul className={x.items}>
          {stickers.map(({ key, src }) => (
            <li
              key={key}
              onClick={onStickerSelect(key)}
              className={classNames(x.item, x[color], {
                [x.selected]: selected?.includes(key),
              })}>
              <img src={src} alt="Sticker" className={x.sticker} />
              {selected?.includes(key) && (
                <CheckCircleIcon className={x.selectedSticker} />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Stickers;
