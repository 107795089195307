import classNames from "classnames";
import { FC, useEffect, useMemo, useState } from "react";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import {
  Tab,
  TabNames,
  TabSubItem,
} from "../../../store/features/rating/rating.mocks";
import { CombinedState } from "../../../store/store";
import EvaluationCard from "../../evaluation-card/evaluation-card";
import x from "./step-1.module.scss";
import { selectTab } from "../../../store/features/rating/rating.slice";
import { useSelector, useDispatch } from "react-redux";

interface IStepOne {}

const tabNames = Object.values(TabNames);

const StepOne: FC<IStepOne> = () => {
  const dispatch = useDispatch();
  const tabs: Tab[] = useSelector((state: CombinedState) => state.rating.tabs);

  const selectedTab: Tab | undefined = useMemo(() => {
    return tabs.find(({ isSelected, name }) => isSelected);
  }, [tabs]);

  const selectedSubItems: TabSubItem[] = useMemo(() => {
    return selectedTab ? [...selectedTab.subItems] : [];
  }, [selectedTab]);

  useEffect(() => {
    let itemsWithRatingQuantity = 0;
    
    selectedSubItems.forEach((subItem) =>
      subItem.rating > 0 ? itemsWithRatingQuantity++ : null
    );
    const isValid = !!selectedTab && itemsWithRatingQuantity === selectedSubItems.length;

    dispatch(onChangeValid({ isValid, path: STATE_PATHS.RATING }));
  }, [dispatch, selectedSubItems, selectedTab, tabs]);

  const [chosenTab, setChosenTab] = useState<TabNames>(
    selectedTab?.name || tabNames[0]
  );
  const onSelectTab = (tabName: TabNames) => () => {
    setChosenTab(tabName);
    dispatch(selectTab({ tabName }));
  };

  const getSubtitle = () => {
    if(selectedTab) {
      return 'Reflektiert, wo Ihr aktuell steht, um zu wissen, wo Ihr hin wollt. Nutzt die aufgeführten Beispiele dabei als Bewertungshilfe.';
    }

    return 'Die Unternehmenskultur 4.0 setzt sich aus vier Themenfeldern zusammen. Wählt das Themenfeld aus, bei dem Ihr aktuell die größten unternehmenskulturellen Herausforderungen seht.'
  }

  return (
    <>
      <p className={x.subtitle}>
        {getSubtitle()}
      </p>
      <div className={x.tabs}>
        {tabs.map(({ name, isSelected }) => (
          <button
            key={name}
            className={classNames(x.tab, { [x.active]: name === chosenTab && isSelected })}
            onClick={onSelectTab(name)}>
            {name}
          </button>
        ))}
      </div>
      <div>
        {selectedSubItems.map((card) => (
          <EvaluationCard
            key={card.key}
            card={{ ...card }}
            tabName={chosenTab}
          />
        ))}
      </div>
    </>
  );
};

export default StepOne;
