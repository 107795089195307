import { FC } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSelector } from "react-redux";
import { CombinedState } from "../../store/store";

import x from "./footer.module.scss";

interface IFooter {
  step: number;
  total: number;
  handleNext: () => void;
}

const Footer: FC<IFooter> = ({ handleNext, step, total }) => {
  const isValid = useSelector((state: CombinedState) => state.navigation.valid);
  const progressBarValue = ((100/ (total - 1)) * step + 1);

  return (
    <footer className={x.footer}>
      <div className={x.inner}>
        {(isValid || step === 0 || step === 1) && (step !== total - 1) && (
          <button className={x.button} onClick={handleNext}>
            <span>Nächste</span>
            <ArrowForwardIcon sx={{
              width: '16px',
              height: '16px',
              '@media (min-width: 1440px)': {
                width: '24px',
                height: '24px',
              },
              '@media (min-width: 2540px)': {
                width: '36px',
                height: '36px',
              },
            }} />
          </button>
        )}
      </div>
      <div
        className={x.statusBar}
        style={{
          background: `linear-gradient(90deg, #ffffff 0%, #ffffff ${progressBarValue}%, 
          #024363 ${progressBarValue}%, #024363 100%)`,
        }}
      />
    </footer>
  );
};

export default Footer;
