import { createSlice } from "@reduxjs/toolkit";

export type GoalState = {
  modal: boolean;
};

const initialState: GoalState = {
  modal: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleModal: (state, { payload }) => {
      state.modal = payload;
    },
    resetGoalState: () => initialState,
  },
});

export const { toggleModal, resetGoalState } = modalSlice.actions;

export default modalSlice.reducer;
