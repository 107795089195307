import classNames from "classnames";
import { FC } from "react";
import sponsorOne from "../../assets/images/sponsors/sponsor-1.png";
import sponsorTwo from "../../assets/images/sponsors/sponsor-2.png";
import sponsorThree from "../../assets/images/sponsors/sponsor-3.png";
import x from "./sponsors.module.scss";

interface ISponsorsProps {
  classes?: string;
}

const Sponsor: FC<ISponsorsProps> = ({ classes }) => {
  return (
    <div className={classNames(x.sponsors, classes)}>
      <img alt="sponsor" src={sponsorOne} />
      <img alt="sponsor" src={sponsorTwo} />
      <img alt="sponsor" src={sponsorThree} />
    </div>
  );
};

export default Sponsor;
