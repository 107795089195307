import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CharacterItem } from "../../../store/features/characters/characters.mocks";
import { onChangeCharacterState } from "../../../store/features/characters/characters.slice";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as CheckCircleIcon } from "../../../assets/images/checkCircle.svg";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import { CombinedState } from "../../../store/store";
import x from "./step-7.module.scss";

interface IStepSeven {}

const StepSeven: FC<IStepSeven> = () => {
  const MAX_PERSONS_SELECTION_NUMBER = 3;

  const isReadOnlyStep: boolean = useSelector(
    (state: CombinedState) => state.navigation.isReadOnlyCharacters
  );
  const characters: CharacterItem[] = useSelector(
    (state: CombinedState) => state.characters.characters
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const allCharacters = characters.filter((s) => s.isSelected);
    const isValid = allCharacters.length > 1 && allCharacters.length < 4;
    dispatch(onChangeValid({ isValid, path: STATE_PATHS.PICKING_CHARACTERS }));
  }, [dispatch, characters]);

  const [chosenCards, setChosenCards] = useState<string[]>([]);

  useEffect(() => {
    const initialSelectedStickers: string[] = [];
    characters.forEach(({ key, isSelected }) => {
      if (isSelected) {
        initialSelectedStickers.push(key);
      }
    });

    setChosenCards(initialSelectedStickers);
  }, [characters]);

  const onCharacterSelect = (characterKey: string) => () => {
    if(chosenCards.length === MAX_PERSONS_SELECTION_NUMBER && !chosenCards.includes(characterKey)) {
      return;
    }

    setChosenCards((prev) => {
      if (prev.includes(characterKey)) {
        return prev.filter((key) => key !== characterKey);
      }

      return [...prev, characterKey];
    });

    dispatch(onChangeCharacterState({ characterKey }));
  };

  return (
    <>
      <p className={x.subtitle}>
        Auf dem Weg zu Eurem Ziel seid Ihr nicht allein, sondern werdet stets
        von anderen Beschäftigten Eures Unternehmens begleitet. Wählt zufällige
        Mitreisende aus, deren Perspektiven Ihr während Eurer Wanderung
        berücksichtigen werdet, damit niemand aus Versehen zurückgelassen wird.
      </p>
      <div className={x.persons}>
        {characters.map(({ key, image }) => (
          <div
            className={classNames(x.person, {
              [x.selectedCard]: chosenCards.includes(key),
              [x.readOnly]: isReadOnlyStep,
            })}
            key={key}
            onClick={onCharacterSelect(key)}>
            <img src={image} alt="person" />
            {chosenCards.includes(key) && (
                <div className={x.selectedContainer}>
                  <CheckCircleIcon className={x.selected} />
                </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default StepSeven;
