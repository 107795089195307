import greenOne from "../../../assets/images/stickers/green-1.svg";
import greenTwo from "../../../assets/images/stickers/green-2.svg";
import greenThree from "../../../assets/images/stickers/green-3.svg";
import greenFour from "../../../assets/images/stickers/green-4.svg";
import redOne from "../../../assets/images/stickers/red-1.svg";
import redTwo from "../../../assets/images/stickers/red-2.svg";
import redThree from "../../../assets/images/stickers/red-3.svg";
import redFour from "../../../assets/images/stickers/red-4.svg";
import yellowOne from "../../../assets/images/stickers/yellow-1.svg";
import yellowTwo from "../../../assets/images/stickers/yellow-2.svg";
import yellowThree from "../../../assets/images/stickers/yellow-3.svg";
import yellowFour from "../../../assets/images/stickers/yellow-4.svg";

import greenOnePng from "../../../assets/images/stickers/green-1.png";
import greenTwoPng from "../../../assets/images/stickers/green-2.png";
import greenThreePng from "../../../assets/images/stickers/green-3.png";
import greenFourPng from "../../../assets/images/stickers/green-4.png";
import redOnePng from "../../../assets/images/stickers/red-1.png";
import redTwoPng from "../../../assets/images/stickers/red-2.png";
import redThreePng from "../../../assets/images/stickers/red-3.png";
import redFourPng from "../../../assets/images/stickers/red-4.png";
import yellowOnePng from "../../../assets/images/stickers/yellow-1.png";
import yellowTwoPng from "../../../assets/images/stickers/yellow-2.png";
import yellowThreePng from "../../../assets/images/stickers/yellow-3.png";
import yellowFourPng from "../../../assets/images/stickers/yellow-4.png";

import { IsSelected, ItemKey, ItemLabel, ItemOrder } from "../../../shared/interfaces/index";
import { AvailableStickerSectionColors } from "./stickers.slice";

export type StickerItem =
	IsSelected &
	ItemKey &
	ItemOrder &
	ItemLabel &
	{
		displayValue: string;
		src: string;
		png: string;
		color: "success" | "warning" | "danger";
	};

export type StickerSection = {
	stickers: StickerItem[];
};

export type StickersState = {
	stickerSections: { [key in AvailableStickerSectionColors]: StickerSection };
};

const redStickers: StickerItem[] = [
	{
		key: `danger-1`,
		order: 1,
		displayValue: `1`,
		labelText: "",
		isSelected: false,
		src: redOne,
		png: redOnePng,
		color: "danger",
	},
	{
		key: `danger-2`,
		order: 2,
		displayValue: `2`,
		labelText: "",
		isSelected: false,
		src: redTwo,
		png: redTwoPng,
		color: "danger",
	},
	{
		key: `danger-3`,
		order: 3,
		displayValue: `3`,
		labelText: "",
		isSelected: false,
		src: redThree,
		png: redThreePng,
		color: "danger",
	},
	{
		key: `danger-4`,
		order: 4,
		displayValue: `4`,
		labelText: "",
		isSelected: false,
		src: redFour,
		png: redFourPng,
		color: "danger",
	},
];

const yellowStickers: StickerItem[] = [
	{
		key: `warning-1`,
		order: 1,
		displayValue: `1`,
		labelText: "",
		isSelected: false,
		src: yellowOne,
		png: yellowOnePng,
		color: "warning",
	},
	{
		key: `warning-2`,
		order: 2,
		displayValue: `2`,
		labelText: "",
		isSelected: false,
		src: yellowTwo,
		png: yellowTwoPng,
		color: "warning",
	},
	{
		key: `warning-3`,
		order: 3,
		displayValue: `3`,
		labelText: "",
		isSelected: false,
		src: yellowThree,
		png: yellowThreePng,
		color: "warning",
	},
	{
		key: `warning-4`,
		order: 4,
		displayValue: `4`,
		labelText: "",
		isSelected: false,
		src: yellowFour,
		png: yellowFourPng,
		color: "warning",
	},
];

const greenStickers: StickerItem[] = [
	{
		key: `success-1`,
		order: 1,
		displayValue: `1`,
		labelText: "",
		isSelected: false,
		src: greenOne,
		png: greenOnePng,
		color: "success",
	},
	{
		key: `success-2`,
		order: 2,
		displayValue: `2`,
		labelText: "",
		isSelected: false,
		src: greenTwo,
		png: greenTwoPng,
		color: "success",
	},
	{
		key: `success-3`,
		order: 3,
		displayValue: `3`,
		labelText: "",
		isSelected: false,
		src: greenThree,
		png: greenThreePng,
		color: "success",
	},
	{
		key: `success-4`,
		order: 4,
		displayValue: `4`,
		labelText: "",
		isSelected: false,
		src: greenFour,
		png: greenFourPng,
		color: "success",
	},
];

export const initialStickerSectionsState: StickersState = {
	stickerSections: {
		danger: {
			stickers: [...redStickers],
		},
		warning: {
			stickers: [...yellowStickers],
		},
		success: {
			stickers: [...greenStickers],
		},
	},
};
