import { createTheme } from '@mui/material';
import { orange } from '@mui/material/colors';
import './theme.augmentation';

export const theme = createTheme({
    status: {
        danger: orange[500],
    },
    header: {
        background: 'rgb(18, 18, 18)',
        color: 'rgb(255, 255, 255)'
    }
});
