import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onChangeStickerLabel } from "../../store/features/stickers/stickers.slice";

import x from "./titled-sticker.module.scss";

interface ITitledSticker {
  color: string;
  displayValue: string;
  labelText: string;
  stickerKey: string;
  src: string;
}

const TitledSticker: FC<ITitledSticker> = ({
  color,
  stickerKey,
  labelText,
  src,
}) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setValue(labelText);
  }, [labelText]);

  const dispatch = useDispatch();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);

    // TODO: Add debounce function before saving value to the store.
    dispatch(
      onChangeStickerLabel({
        sectionKey: color,
        stickerKey,
        labelValue: e.target.value,
      })
    );
  };

  return (
    <div className={x.sticker}>
      <div className={classNames(x.item, x[color])}>
        <img src={src} alt="Sticker" />
      </div>
      <input
        className={x.input}
        value={value}
        placeholder="Name"
        maxLength={40}
        onChange={handleChange}
      />
    </div>
  );
};

export default TitledSticker;
