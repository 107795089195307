import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ObstacleItem } from "../../store/features/obstacles/obstacles.mocks";
import { CombinedState } from "../../store/store";
import StepOne from "../steps/step-1/step-1";
import StepTwo from "../steps/step-2/step-2";
import StepThree from "../steps/step-3/step-3";
import StepFour from "../steps/step-4/step-4";
import StepFive from "../steps/step-5/step-5";
import StepSix from "../steps/step-6/step-6";
import StepSeven from "../steps/step-7/step-7";
import StepEight from "../steps/step-8/step-8";
import StepNine from "../steps/step-9/step-9";
import StepTen from "../steps/step-10/step-10";

import x from "./body.module.scss";
import About from "../about/about";
import BeforeStart from "../before-start/before-start";
import classNames from "classnames";
import { Tab } from "../../store/features/rating/rating.mocks";
import LoadingOverlay from 'react-loading-overlay';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';

interface IBodyProps {
  step: number;
}

const titles = [
  "Digitale Corporate Culture Map",
  "Bevor es los geht",
  "Wählt das Themenfeld aus, das Ihr mit einem Maßnahmenplan weiterentwickeln wollt.",
  "Formuliert ein konkretes Ziel, für das Ihr einen Maßnahmenplan entwickeln wollt.",
  "Wählt aus jeder Kategorie mindestens einen Ausrüstungsgegenstand aus.",
  "Benennt die Ausrüstungsgegenstände.",
  "Wählt zwei bis vier Stolperstellen aus.",
  "Benennt die Stolperstellen und bringt sie in eine chronologische Reihenfolge.",
  "Wählt zwei bis drei Mitreisende aus.",
  "Lest die Hintergrundgeschichten Eurer Mitreisenden. ",
  "Setzt einen Meilenstein zur Überwindung der Stolperstelle.",
  "Herzlichen Glückwunsch! Ihr habt die Wanderroute zu Eurem Ziel fertiggestellt.",
];

const Body: FC<IBodyProps> = ({ step }) => {
  const selectedObstacles: ObstacleItem[] = useSelector(
    (state: CombinedState) =>
      state.obstacles.obstacles.filter((s) => s.isSelected)
  );
  const lastStep = 9 + selectedObstacles.length + 1;

  const tabs: Tab[] = useSelector((state: CombinedState) => state.rating.tabs);

  const hasSelected: boolean = useMemo(() => {
    return Boolean(tabs.find(({ isSelected, name }) => isSelected));
  }, [tabs]);

  let actualTitle =
    step < 10 ? titles[step] : step === lastStep ? titles[11] : titles[10];

  if(step === 2 && hasSelected) {
    actualTitle = 'Bewertet wie stark die einzelnen Handlungsfelder innerhalb des ausgewählten Themenfeldes ausgeprägt sind.'
  }

  const [loading, setLoading] = useState(false);

  const spinnerStyles = css`
    border-width: 4px;
`;

  return (
    <div className={x.backgroundColor}>
      <div className={x.body}>
        <LoadingOverlay
            active={loading}
            className={x.innerLoading}
            spinner={<ClipLoader speedMultiplier={0.75} css={spinnerStyles} color={'#A5D8D9'} />}
            styles={{
              overlay: (base) => ({
                ...base,
                background: 'rgba(1, 55, 82, 0.85)',
                borderRadius: '15px',
                marginLeft: '25px',
                width: 'calc(100% - 25px)',
                height: 'calc(100%)'
              })
            }}
        >
          <div
            className={classNames(x.inner, {
              [x.innerBackground]: step === 0 || step === 1,
            })}>
            <h2 className={x.stepHeader}>{actualTitle}</h2>
            {step === 0 && <About />}
            {step === 1 && <BeforeStart />}
            {step === 2 && <StepOne />}
            {step === 3 && <StepTwo />}
            {step === 4 && <StepThree />}
            {step === 5 && <StepFour />}
            {step === 6 && <StepFive />}
            {step === 7 && <StepSix />}
            {step === 8 && <StepSeven />}
            {step === 9 && <StepEight />}
            {selectedObstacles.sort((a,b) => a.customOrder > b.customOrder ? 1 : -1).map((o, index) => {
              return step === 10 + index ? (
                <StepNine key={`${o.key}_index`} obstacle={o} />
              ) : null;
            })}
            {step === lastStep && <StepTen setLoading={setLoading} />}
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default Body;
