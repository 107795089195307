import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeGoalText } from "../../../store/features/goal/goal.slice";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import { CombinedState } from "../../../store/store";
import x from "./step-2.module.scss";

interface IStepTwo {}

const StepTwo: FC<IStepTwo> = () => {
  const [value, setValue] = useState<string>("");

  const goalText: string = useSelector(
    (state: CombinedState) => state.goal.goalText
  );

  useEffect(() => {
    setValue(goalText);
  }, [goalText]);

  const dispatch = useDispatch();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
    // TODO: Add debounce function before saving value to the store.
    dispatch(changeGoalText(e.target.value));
  };

  useEffect(() => {
    const isValid = !!goalText;
    dispatch(onChangeValid({ isValid, path: STATE_PATHS.GOAL }));
  }, [dispatch, goalText]);

  return (
    <>
      <p className={x.subtitle}>
        Durch Eure Bewertung habt Ihr das Handlungsfeld identifiziert, bei dem
        Ihr den größten unternehmenskulturellen Handlungsbedarf seht. Es sollte
        Euer Ziel sein, dieses weiterzuentwickeln.
      </p>
      <div className={x.inputField}>
        <input
          className={x.input}
          value={value}
          placeholder="Name"
          maxLength={41}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default StepTwo;
