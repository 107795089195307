import { createSlice } from "@reduxjs/toolkit";

export type GoalState = {
	goalText: string;
};

const initialState: GoalState = {
	goalText: "",
};

export const goalSlice = createSlice({
	name: "goalStep",
	initialState,
	reducers: {
		changeGoalText: (state, { payload }) => {
			state.goalText = payload;
		},
		resetGoalState: () => initialState,
	},
});

export const { changeGoalText, resetGoalState } = goalSlice.actions;

export default goalSlice.reducer;
