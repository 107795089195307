import React, { FC, useState } from "react";
import { ReactComponent as RouteImage } from "../../assets/images/route.svg";
import { ReactComponent as RouteTheoryImage } from "../../assets/images/route-theory.svg";
import Sponsor from "../sponsors/sponsors";
import ArrowDownSvg from "../../assets/images/arrowDown.svg";
import x from "./about.module.scss";
import classNames from "classnames";

const openCloseChevron = (status: boolean) => {
  return (
    <img
      className={classNames(x.icon, status && x.iconRotated)}
      src={ArrowDownSvg}
      alt={""}
    />
  );
};

const About: FC = () => {
  const [showMethod, setShowMethod] = useState(false);
  const [showTheory, setShowTheory] = useState(false);
  const [showProject, setShowProject] = useState(false);

  return (
    <React.Fragment>
      <div className={x.subtitle}>
        <p className={x.subtitleText}>
          Mit der Corporate Culture Map findet Ihr den Weg zu Eurer
          widerstandsfähigen, chancengleichen und digitalen Unternehmenskultur
          4.0. Unterwegs identifiziert Ihr dabei Eure vorhandene
          Wanderausrüstung, erkennt frühzeitig mögliche Stolperstellen und setzt
          konkrete Meilensteine, deren Umsetzung Euch zu Eurem Gipfel führt.
        </p>
        <p className={x.subtitleText}>
          Im Ergebnis erhaltet Ihr einen maßgeschneiderten Strategie- und
          Maßnahmenplan zur Entwicklung Eurer Unternehmenskultur 4.0{" "}
        </p>
      </div>
      <div className={x.moreInfo}>
        <div className={x.card}>
          <div
            className={x.cardHeader}
            onClick={() => setShowMethod(!showMethod)}
          >
            <span>Mehr über die Methode erfahren</span>
            {openCloseChevron(showMethod)}
          </div>
          {showMethod && (
            <div className={x.cardBody}>
              <p className={x.cardBodyText}>
                Mit der Corporate Culture Map durchlauft Ihr einen intensiven
                Strategieprozess. Die Map leitet Euch dabei durch mehrere
                Arbeitsphasen, in denen Ihr unterschiedliche Aufgaben
                bearbeitet.{" "}
              </p>
              <RouteImage className={x.routeImage} />
              <p className={x.cardBodyText}>
                Zu Beginn entscheidet Ihr, welches Themenfeld der
                Unternehmenskultur 4.0 (Kollaboration, Wissen & Entwicklung,
                Nachhaltigkeit & Soziales, Chancengleichheit) Ihr
                weiterentwickeln möchtet. Die Map hilft Euch mittels einer
                Standortbestimmung dabei, ein konkretes und für Euch relevantes
                unternehmenskulturelles Ziel zu formulieren. Anschließend
                arbeitet Ihr heraus, welche Ressourcen Euch bereits im
                Unternehmen für die Erreichung des Ziels zur Verfügung stehen.
                Als nächstes identifiziert Ihr die Herausforderungen, die Euch
                auf dem Weg zu Eurem Ziel begegnen werden und entwickelt Schritt
                für Schritt eine Lösungsstrategie, die unterschiedliche
                Beschäftigtengruppen chancengerecht miteinbezieht. Im Ergebnis
                erhaltet Ihr eine digitale Karte mit konkreten Meilensteinen,
                die Ihr mit anderen teilen und als Poster ausdrucken könnt.
              </p>
            </div>
          )}
        </div>
        <div className={x.card}>
          <div
            className={x.cardHeader}
            onClick={() => setShowTheory(!showTheory)}
          >
            <span>Mehr über den theoretischen Hintergrund erfahren</span>
            {openCloseChevron(showTheory)}
          </div>
          {showTheory && (
            <div className={x.cardBody}>
              <p className={x.cardBodyText}>
                Der Arbeitsprozess der Corporate Culture Map baut auf der
                etablierten Coaching-Methode des GROW-Modells (Whitmore 1992)
                auf. Das Akronym GROW steht für die Begriffe Goal, Reality,
                Options & Obstacles und Wayfinding. Die vier Bausteine des
                Modells setzen den Rahmen für eine individuelle Zieldefinition
                und Situationsanalyse, die Identifikation von Herausforderungen
                und Handlungsoptionen sowie die Formulierung konkreter
                Umsetzungsschritte. Die Corporate Culture Map setzt
                konzeptionell an den vier Schritten des GROW-Modells an und
                entwickelt dieses methodisch zur Organisationsentwicklung
                weiter.
              </p>
              <RouteTheoryImage />
            </div>
          )}
        </div>
        <div className={x.card}>
          <div
            className={x.cardHeader}
            onClick={() => setShowProject(!showProject)}
          >
            <span>Mehr über das Projekt erfahren</span>
            {openCloseChevron(showProject)}
          </div>
          {showProject && (
            <div className={x.cardBody}>
              <p className={x.cardBodyText}>
                Die "Corporate Culture Map" ist das Ergebnis des Projekts
                "Corporate Culture Lab - Ko-kreative Gestaltung einer
                widerstandsfähigen, chancengleichen und digitalen
                Unternehmenskultur 4.0". Das Projekt wurde vom Ministerium für
                Wirtschaft, Arbeit und Tourismus Baden-Württemberg gefördert und
                vom Center for Responsible Research and Innovation CeRRI des
                Fraunhofer-Instituts für Arbeitswirtschaft und Organisation IAO
                im Austausch mit den Projektpartnern AGILeVIA GmbH, ANDREAS
                STIHL AG & Co. KG, Balluff GmbH, HENSOLDT Sensors GmbH, Mader
                GmbH & Co. KG, Verein Deutscher Ingenieure Baden-Württemberg und
                Vollert Anlagenbau GmbH umgesetzt.
              </p>
            </div>
          )}
        </div>
      </div>
      <Sponsor />
    </React.Fragment>
  );
};

export default About;
