import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";

import Sponsor from "../../sponsors/sponsors";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import Map from '../../map/map';

import x from "./step-10.module.scss";
import { saveAs } from "file-saver";
import { pdf } from '@react-pdf/renderer';

import { CombinedState } from '../../../store/store';
import { CharacterItem } from '../../../store/features/characters/characters.mocks';
import { ObstacleItem } from '../../../store/features/obstacles/obstacles.mocks';
import { pdfjs } from 'react-pdf';
import { DateTime } from 'luxon';
import { toggleModal } from '../../../store/features/modal/modal.slice';

type LoadingState = {
  setLoading: (state: boolean) => void;
};

const StepTen: FC<LoadingState> = ({ setLoading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onChangeValid({ isValid: true, path: STATE_PATHS.FINAL_STEP }));
  }, [dispatch]);

  const onResetStorage = () => {
    dispatch(toggleModal(true));
  };

  const goalText: string = useSelector(
      (state: CombinedState) => state.goal.goalText
  );

  const stickerSections = useSelector(
      (state: CombinedState) => state.stickers.stickerSections
  );

  const danger = stickerSections["danger"].stickers.filter((s) => s.isSelected);
  const warning = stickerSections["warning"].stickers.filter((s) => s.isSelected);
  const success = stickerSections["success"].stickers.filter((s) => s.isSelected);

  const characters: CharacterItem[] = useSelector(
      (state: CombinedState) => state.characters.characters.filter((s) => s.isSelected)
  );

  const selectedObstacles: ObstacleItem[] = useSelector(
      (state: CombinedState) =>
          state.obstacles.obstacles.filter((s) => s.isSelected)
  );

  const obstacles = ([ ...selectedObstacles ]).sort((a,b) => a.customOrder > b.customOrder ? 1 : -1);
  const map = (<Map destination={goalText} danger={danger} warning={warning} success={success} characters={characters} obstacles={obstacles} />);

  const generatePdf = async (action: ((blob: Blob) => void)) => {
    setLoading(true);

    return new Promise(async () => {
      const MapDocument = () => (map);

      const blob = await pdf(
          <MapDocument />
      ).toBlob();

      action(blob);
      setLoading(false);
    });
  };

  const onDownloadPdf = async () => {
    await generatePdf((blob) => {

      const date = DateTime.now().toISODate({ format: 'basic' });
      const name = `Karte-${date}`;

      saveAs(blob, name);
    });
  };

  const onPrintPdf = async () => {
    await generatePdf((blob) => {
      const dataUrl = window.URL.createObjectURL(blob);
      const pdfWindow = window.open(dataUrl);
      pdfWindow?.print();
    });
  };

  pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

  const iconStyles = {
    width: '11px',
    height: '11px',
    '@media (min-width: 1440px)': {
      width: '21px',
      height: '21px',
    },
    '@media (min-width: 2540px)': {
      width: '33px',
      height: '33px',
    },
  };


  return (
    <>
      <p className={x.subtitle}>
        Speichert Eure Corporate Culture Map ab, druckt sie aus und hängt sie
        zur Erinnerung an den Weg, der vor Euch liegt, auf. Mit der Umsetzung
        der Meilensteine nähert Ihr Euch Schritt für Schritt Eurer
        widerstandsfähigen, chancengleichen und digitalen Unternehmenskultur
        4.0.
      </p>

      <div className={x.body}>
        <div className={x.actions}>
          <button className={x.tab} onClick={onDownloadPdf}>
            Speichern <FileDownloadOutlinedIcon sx={iconStyles} />{" "}
          </button>
          <button className={x.tab} onClick={onPrintPdf}>
            Drucken <LocalPrintshopIcon sx={iconStyles} />{" "}
          </button>
          <button className={x.tab} onClick={onResetStorage}>
            Zurücksetzen <SettingsBackupRestoreIcon sx={iconStyles} />{" "}
          </button>
        </div>
        <div className={x.contacts}>
          <p>
            Bei Fragen und Unterstützungbedarf zur Entwicklung einer
            Unternehmenskultur 4.0 wenden Sie sich an:
          </p>
          <p>Vivien Iffländer</p>
          <p>Center for Responsible Research and Innovation CeRRI</p>
          <p>Fraunhofer-Institut für Arbeitswirtschaft und Organisation IAO</p>
          <p><a style={{ color: '#ffffff', textDecoration: 'none' }} rel="noreferrer" target='_blank' href='https://www.google.com/maps/place/Fraunhofer+Center+for+Responsible+Research+and+Innovation+CeRRI/@52.5070361,13.329333,17.49z/data=!4m8!1m2!3m1!2sFraunhofer+Center+for+Responsible+Research+and+Innovation+CeRRI!3m4!1s0x47a850fddc44b109:0xe3c99949995c2fbb!8m2!3d52.5070741!4d13.3298986'>Hardenbergstraße 20, 10623 Berlin</a></p>
          <p><a style={{ color: '#ffffff', textDecoration: 'none' }} href='tel:+49306807969297'>Telefon +49 30 6807969 – 297</a></p>
          <p><a style={{ color: '#ffffff', textDecoration: 'none' }} href='mailto:vivien.ifflaender@iao.fraunhofer.de'>vivien.ifflaender@iao.fraunhofer.de</a></p>
          <p><a style={{ color: '#ffffff', textDecoration: 'none' }} href='https://www.cerri.iao.fraunhofer.de/' rel="noreferrer" target='_blank'>www.cerri.iao.fraunhofer.de</a></p>
        </div>
      </div>
      <Sponsor classes={x.sponsors} />
    </>
  );
};

export default StepTen;
