import {
  IsSelected,
  ItemKey,
  ItemLabel,
  ItemOrder,
} from "../../../shared/interfaces/index";
import { StickerItem } from "../stickers/stickers.mocks";

import gletscherspalte from "../../../assets/images/obstacles/1_Gletscherspalte.png";
import eingeschneit from "../../../assets/images/obstacles/2_Eingeschneit.png";
import nebelfront from "../../../assets/images/obstacles/3_Nebelfront.png";
import knochelbruch from "../../../assets/images/obstacles/4_Knchelbruch.png";
import gewitter from "../../../assets/images/obstacles/5_Gewitter.png";
import absturz from "../../../assets/images/obstacles/6_Absturz.png";
import kletterpartie from "../../../assets/images/obstacles/7_Kletterpartie_.png";
import gesteinslawine from "../../../assets/images/obstacles/8_Gesteinslawine.png";
import temperatursturz from "../../../assets/images/obstacles/9_temperatursturz.png";
import ausrstungVergessen from "../../../assets/images/obstacles/10_Ausrstung vergessen.png";
import steilePassage from "../../../assets/images/obstacles/11_steile Passage.png";

const obstacleImages = [
  gletscherspalte,
  eingeschneit,
  nebelfront,
  knochelbruch,
  gewitter,
  absturz,
  kletterpartie,
  gesteinslawine,
  temperatursturz,
  ausrstungVergessen,
  steilePassage,
];

const obstacleNames = [
  "Gletscherspalte",
  "Eingeschneit",
  "Nebelfront",
  "Knöchelbruch",
  "Gewitter",
  "Absturz",
  "Kletterpartie",
  "Gesteinslawine",
  "Temperatursturz",
  "Ausrüstung vergessen",
  "Steile Passage",
];

export type ObstacleMilestone = {
  description: string;
  responsiblePerson: string;
  weeks: string;
  months: string;
  years: string;
  name: string;
  pickedStickers: StickerItem[];
};

export type ObstacleItem = IsSelected &
  ItemKey &
  ItemOrder &
  ItemLabel & {
    image: string;
    milestone: ObstacleMilestone;
    text: string;
    customOrder: number;
  };

export type ObstaclesState = {
  orderCount: number;
  obstacles: ObstacleItem[];
};

const mockedObstacleItem: ObstacleItem = {
  // TODO: Replace with more descriptive key.
  key: "",
  order: 0,
  image: "",
  labelText: "",
  text: "",
  isSelected: false,
  customOrder: -1,
  milestone: {
    description: "",
    responsiblePerson: "",
    weeks: "",
    months: "",
    years: "",
    name: "",
    pickedStickers: [],
  },
};

export const initialObstaclesState: ObstaclesState = {
  orderCount: 1,
  obstacles: [...Array(11)].map((_, i) => ({
    ...mockedObstacleItem,
    key: `${i + 1}`,
    order: i + 1,
    image: obstacleImages[i],
    text: obstacleNames[i],
  })),
};
