import classNames from "classnames";
import { FC, useRef } from "react";
import { ReactComponent as CheckCircleIcon } from "../../assets/images/checkCircle.svg";

import x from "./obstacle-card.module.scss";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { swapObstaclesIndex } from "../../store/features/obstacles/obstacles.slice";

// TODO: @Vitalii Verdiiev
//	 Do we really have a situation when [key] prop could be `undefined`?
//	 As well for [onclick] and [text] props.
interface IObstacleCardProps {
  classes?: string;
  bodyClasses?: string;
  text?: string;
  onclick?: () => void;
  key?: string;
  image: string;
  subname?: string;
  customOrder?: number;
  dragMode?: boolean;
}

const ObstacleCard: FC<IObstacleCardProps> = ({
  classes,
  bodyClasses,
  text,
  onclick,
  image,
  subname,
  customOrder,
  dragMode,
}) => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag(
    {
      type: "CARD",
      item: () => {
        return { customOrder };
      },
      canDrag: () => {
        return !!dragMode;
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [customOrder, dragMode]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "CARD",
      drop(item: any) {
        dispatch(
          swapObstaclesIndex({
            targetKey: customOrder,
            operationKey: item.customOrder,
          })
        );
      },
    }),
    [customOrder]
  );

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={classNames(x.card, classes, { [x.dragging]: isDragging })}
      onClick={() => onclick && onclick()}
    >
      <div className={x.cardHeader}>
        <img src={image} alt={text} />
      </div>
      <div className={classNames(x.cardBody, bodyClasses)}>
        {text && <span>{text}</span>}
        {subname && <span>{subname}</span>}
      </div>
      {classes?.includes("selectedCard") && (
          <div className={x.selectedContainer}>
              <CheckCircleIcon className={x.selected} />
          </div>
      )}
    </div>
  );
};

export default ObstacleCard;
