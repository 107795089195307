import { useDispatch } from "react-redux";
import { resetCharactersState } from "../../store/features/characters/characters.slice";
import { resetGoalState } from "../../store/features/goal/goal.slice";
import { resetNavigationState } from "../../store/features/navigation/navigation.state";
import { resetObstaclesState } from "../../store/features/obstacles/obstacles.slice";
import { resetRatingState } from "../../store/features/rating/rating.slice";
import { resetStickersState } from "../../store/features/stickers/stickers.slice";

const PERSIST_ACCESS_KEY = "persist:root";

function useResetStorage(): { resetStorage: () => void } {
	const dispatch = useDispatch();

	const resetStorage = () => {
		localStorage.removeItem(PERSIST_ACCESS_KEY);
		dispatch(resetNavigationState());
		dispatch(resetRatingState());
		dispatch(resetGoalState());
		dispatch(resetStickersState());
		dispatch(resetObstaclesState());
		dispatch(resetCharactersState());
	};

	return { resetStorage };
}

export default useResetStorage;
