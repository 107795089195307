import { createSlice } from "@reduxjs/toolkit";
import { MilestoneFormFieldNames } from "../../../components/form/form";
import { initialObstaclesState as initialState } from "./obstacles.mocks";

export const obstaclesSlice = createSlice({
	name: "obstaclesStep",
	initialState,
	reducers: {
		onChangeObstacleState: (state, { payload }) => {
			const { obstacleKey } = payload;

			// Find clicked obstacle
			const operationObstacle = state.obstacles.find(obstacle => obstacle.key === obstacleKey);

			// Get array of selected obstacles
			const selectedObstacles = state.obstacles.filter((selectedObstacle) => selectedObstacle.isSelected);

			if(operationObstacle) {
				if(operationObstacle.isSelected) {
					// Move obstacles that was after deselected obstacle on on 1 index lower
					selectedObstacles.forEach((selectedObstacle) => {
						if(selectedObstacle.customOrder > operationObstacle.customOrder) {
							selectedObstacle.customOrder -= 1;
						}
					})
					operationObstacle.isSelected = false;
					operationObstacle.customOrder = -1;
				} else {
					operationObstacle.isSelected = true;
					operationObstacle.customOrder = selectedObstacles.length + 1;
				}
			}
		},
		onChangeObstacleLabel: (state, { payload }) => {
			const { obstacleKey, labelValue } = payload;

			state.obstacles.forEach((obstacle) => {
				if (obstacle.key === obstacleKey) {
					obstacle.labelText = labelValue;
				}
			});
		},
		onPickMilestoneSticker: (state, { payload }) => {
			const { obstacleKey, sticker } = payload;
			const { key: stickerKey } = sticker;

			state.obstacles.forEach((obstacle) => {
				if (obstacle.key === obstacleKey) {
					const existedStickerIndex = obstacle.milestone.pickedStickers.findIndex(({ key }) => stickerKey === key);

					if(obstacle.milestone.pickedStickers.length === 3 || existedStickerIndex !== -1) {
						return;
					}

					obstacle.milestone.pickedStickers.push(sticker);
				}
			});
		},
		onRemoveMilestoneSticker: (state, { payload }) => {
			const { obstacleKey, sticker } = payload;
			const { key: stickerKey } = sticker;

			state.obstacles.forEach((obstacle) => {
				if (obstacle.key === obstacleKey) {
					const existedStickerIndex = obstacle.milestone.pickedStickers.findIndex(({ key }) => stickerKey === key);

					if(existedStickerIndex === -1) {
						return;
					}

					obstacle.milestone.pickedStickers.splice(existedStickerIndex, 1);
				}
			});
		},
		onObstacleFormTyping: (state, { payload }) => {
			const { obstacleKey, fieldName, fieldValue } = payload;

			state.obstacles.forEach((obstacle) => {
				if (obstacle.key === obstacleKey) {
					obstacle.milestone[fieldName as MilestoneFormFieldNames] = fieldValue;
				}
			});
		},
		swapObstaclesIndex: (state, { payload }) => {
			const { targetKey, operationKey } = payload;

			const operationObstacle = state.obstacles.find(obstacle => obstacle.customOrder === operationKey);

			const targetObstacle = state.obstacles.find(obstacle => obstacle.customOrder === targetKey);

			if(operationObstacle && targetObstacle) {
				const targetIndex = targetObstacle.customOrder;

				const prevIndex = operationObstacle.customOrder;
	
				operationObstacle.customOrder = targetIndex;

				targetObstacle.customOrder = prevIndex;
			}
		},
		resetObstaclesState: () => initialState,
	},
});

export const {
	onChangeObstacleState,
	onChangeObstacleLabel,
	onPickMilestoneSticker,
	onRemoveMilestoneSticker,
	onObstacleFormTyping,
	resetObstaclesState,
	swapObstaclesIndex
} = obstaclesSlice.actions;

export default obstaclesSlice.reducer;
