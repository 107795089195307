import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { CharactersState } from "./features/characters/characters.mocks";
import {
  INavigationState,
  navigationReducer,
} from "./features/navigation/navigation.state";
import { ObstaclesState } from "./features/obstacles/obstacles.mocks";
import { RatingState } from "./features/rating/rating.mocks";
import ratingReducer from "./features/rating/rating.slice";
import goalReducer, { GoalState } from "./features/goal/goal.slice";
import { StickersState } from "./features/stickers/stickers.mocks";
import stickersReducer from "./features/stickers/stickers.slice";
import obstaclesReducer from "./features/obstacles/obstacles.slice";
import charactersReducer from "./features/characters/characters.slice";
import modalReducer from "./features/modal/modal.slice";

export type CombinedState = {
  navigation: INavigationState;
  rating: RatingState;
  goal: GoalState;
  stickers: StickersState;
  obstacles: ObstaclesState;
  characters: CharactersState;
};

// TODO: Inject correct interface.
const reducers = combineReducers({
  navigation: navigationReducer,
  rating: ratingReducer,
  goal: goalReducer,
  stickers: stickersReducer,
  obstacles: obstaclesReducer,
  characters: charactersReducer,
  modal: modalReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
