import { createSlice } from "@reduxjs/toolkit";
import { initialStickerSectionsState as initialState } from "./stickers.mocks";

export type AvailableStickerSectionColors = "danger" | "warning" | "success";

export const stickersSlice = createSlice({
  name: "stickersStep",
  initialState,
  reducers: {
    onChangeStickerState: (state, { payload }) => {
      const { sectionKey, stickerKey } = payload;

      state.stickerSections[
        sectionKey as AvailableStickerSectionColors
      ].stickers.forEach((sticker) => {
        if (sticker.key === stickerKey) {
          sticker.isSelected = !sticker.isSelected;
        }
      });
    },
    onChangeStickerLabel: (state, { payload }) => {
      const { sectionKey, stickerKey, labelValue } = payload;

      state.stickerSections[
        sectionKey as AvailableStickerSectionColors
      ].stickers.forEach((sticker) => {
        if (sticker.key === stickerKey) {
          sticker.labelText = labelValue;
        }
      });
    },
    resetStickersState: () => initialState,
  },
});

export const {
  onChangeStickerState,
  onChangeStickerLabel,
  resetStickersState,
} = stickersSlice.actions;

export default stickersSlice.reducer;
