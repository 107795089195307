import { createSlice } from "@reduxjs/toolkit";
import { initialCharactersState as initialState } from "./characters.mocks";

export const charactersSlice = createSlice({
	name: "charactersStep",
	initialState,
	reducers: {
		onChangeCharacterState: (state, { payload }) => {
			const { characterKey } = payload;

			state.characters.forEach((character) => {
				if (character.key === characterKey) {
					character.isSelected = !character.isSelected;
				}
			});
		},
		resetCharactersState: () => initialState,
	},
});

export const { onChangeCharacterState, resetCharactersState } = charactersSlice.actions;

export default charactersSlice.reducer;
