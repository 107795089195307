export interface IConfigurationStep {
  id: number;
  title: string;
  description: string;
}

export const configuration: { steps: IConfigurationStep[] } = {
  steps: [
    { id: 0, title: "About", description: "-2" },
    { id: 1, title: "Before Start", description: "-1" },
    { id: 2, title: "Introduction", description: "0" },
    { id: 3, title: "Rating System", description: "1" },
    { id: 4, title: "Destination", description: "2" },
    { id: 5, title: "Stickers and Association", description: "3" },
    { id: 6, title: "Obstacles", description: "4" },
    { id: 7, title: "Choose Character", description: "5" },
    { id: 8, title: "Connect Resources and Obstacles", description: "6" },
    { id: 9, title: "Milestones", description: "7" },
    { id: 10, title: "Summary", description: "8" },
    { id: 11, title: "Finish", description: "9" },
  ],
};
