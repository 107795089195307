import {
  IsSelected,
  ItemKey,
  ItemOrder,
} from "../../../shared/interfaces/index";
import { dummyData } from "./dummy.characters.mocks";

export type CharacterItem = IsSelected &
  ItemKey &
  ItemOrder & {
    name: string;
    age: number;
    text: string;
    image: string;
  };

export type CharactersState = {
  characters: CharacterItem[];
};

export const initialCharactersState: CharactersState = {
  characters: [...Array(14)].map((_, i) => ({
    key: `${i + 1}`,
    order: i + 1,
    age: dummyData[i].age,
    image: dummyData[i].image,
    name: dummyData[i].name,
    text: dummyData[i].text,
    isSelected: false,
  })),
};
