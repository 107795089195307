import React, { FC } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { useDispatch, useSelector } from "react-redux";
import { CombinedState } from "../../store/store";

import x from "./header.module.scss";
import { toggleModal } from "../../store/features/modal/modal.slice";

interface IHeader {
  handleBack: () => void;
}

const Header: FC<IHeader> = ({ handleBack }) => {
  const isFirstStep = useSelector(
    (state: CombinedState) => state.navigation.current < 1
  );
  const dispatch = useDispatch();

  const onResetStorage = () => {
    dispatch(toggleModal(true));
  };

  return (
    <header className={x.header}>
      <div className={x.inner}>
        <div>
          {isFirstStep ? null : (
            <button className={x.button} onClick={handleBack}>
              <ArrowBackIcon sx={{
                width: '16px',
                height: '16px',
                '@media (min-width: 1440px)': {
                  width: '24px',
                  height: '24px',
                },
                '@media (min-width: 2540px)': {
                  width: '36px',
                  height: '36px',
                }
              }}/>
              <span>Zurück</span>
            </button>
          )}
        </div>
        <button className={x.button} onClick={onResetStorage}>
          <SettingsBackupRestoreIcon sx={{
            '@media (min-width: 1440px)': {
              width: '26px',
              height: '26px',
            },
            '@media (min-width: 2540px)': {
              width: '38px',
              height: '38px',
            }
          }}/>
        </button>
      </div>
    </header>
  );
};

export default Header;
