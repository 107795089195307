import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import { ObstacleItem } from "../../../store/features/obstacles/obstacles.mocks";
import { onChangeObstacleLabel } from "../../../store/features/obstacles/obstacles.slice";
import { CombinedState } from "../../../store/store";
import ObstacleCard from "../../obstacle-card/obstacle-card";
import x from "./step-6.module.scss";

interface IStepSix {}

const StepSix: FC<IStepSix> = () => {
  const obstacles: ObstacleItem[] = useSelector(
    (state: CombinedState) => state.obstacles.obstacles
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const allObstacles = obstacles
      .filter((s) => s.isSelected)
      .map((s) => s.labelText);

    const isValid = !allObstacles.includes("");
    dispatch(onChangeValid({ isValid, path: STATE_PATHS.LABELING_OBSTACLES }));
  }, [dispatch, obstacles]);

  const sorted = obstacles
    .filter((o) => o.isSelected)
    .sort((a, b) => (a.customOrder > b.customOrder ? 1 : -1));

  return (
    <>
      <p className={x.subtitle}>
        Die Stolperstellen werden Euch nach und nach auf Eurer Wanderung
        begegnen. Überlegt, in welcher Reihenfolge Ihr voraussichtlich auf dem
        Weg zu Eurem Ziel auf die Herausforderungen treffen werdet.
      </p>
      <div className={x.cards}>
        {sorted.map(({ key, customOrder, labelText, text, image }) => {
          return (
            <ElevationInputCard
              key={customOrder}
              obstacleKey={key}
              customOrder={customOrder}
              labelText={labelText}
              text={text}
              image={image}
            />
          );
        })}
      </div>
    </>
  );
};

interface IElevationInputCard {
  obstacleKey: string;
  customOrder: number;
  labelText: string;
  image: string;
  text: string;
}

const ElevationInputCard: FC<IElevationInputCard> = ({
  labelText,
  obstacleKey,
  customOrder,
  image,
  text,
}) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setValue(labelText);
  }, [labelText]);

  const dispatch = useDispatch();

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);

    // TODO: Add debounce function before saving value to the store.
    dispatch(
      onChangeObstacleLabel({ obstacleKey, labelValue: e.target.value })
    );
  };

  return (
    <div className={x.card}>
      <div className={x.cardHeader}>
        <span className={x.order}>{customOrder}</span>
      </div>
      <div className={classNames([x.card, x.drop])}></div>
      <ObstacleCard
        customOrder={customOrder}
        image={image}
        classes={classNames(x.card, { [x.drag]: true })}
        text={text}
        dragMode
      />
      <input
        className={x.input}
        value={value}
        onChange={onChangeHandler}
        maxLength={29}
        placeholder="Name"
      />
    </div>
  );
};

export default StepSix;
