import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeValid,
  STATE_PATHS,
} from "../../../store/features/navigation/navigation.state";
import { CombinedState } from "../../../store/store";
import Stickers from "../../stickers/stickers";

import x from "./step-3.module.scss";

interface IStepThree {}

const StepThree: FC<IStepThree> = () => {
  const stickerSections = useSelector(
    (state: CombinedState) => state.stickers.stickerSections
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const allStickers = stickerSections["danger"].stickers
      .concat(stickerSections["warning"].stickers)
      .concat(stickerSections["success"].stickers)
      .map((s) => s.isSelected);

    const isValid = allStickers.includes(true);
    dispatch(onChangeValid({ isValid, path: STATE_PATHS.PICKING_STICKERS }));
  }, [dispatch, stickerSections]);

  return (
    <React.Fragment>
      <p className={x.subtitle}>
        Wanderausrüstung kann für die Erreichung Eures Ziels nützlich sein.
        Überlegt, welche Zuständigkeiten & Initiativen, Fähigkeiten &
        Erfahrungen und Methoden & Tools bereits in Eurem Unternehmen vorhanden
        sind und wählt Bilder aus, die symbolisch dafür stehen.
      </p>
      <div className={x.stickerTreeBlock}>
        <Stickers color="danger" sectionData={stickerSections["danger"]} />
        <Stickers color="success" sectionData={stickerSections["success"]} />
        <Stickers color="warning" sectionData={stickerSections["warning"]} />
      </div>
    </React.Fragment>
  );
};

export default StepThree;
